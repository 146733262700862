import React, { useContext } from 'react'
import './Banner.css';
import { ProductContext } from '../../context/ProductContext';

const Banner = () => {
   const { textBanner } = useContext( ProductContext );

    return (
        <>
    <div
     className='superiorBanner'
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        width: '100%'
      }}
    >

      <h2>{textBanner}</h2>
    </div>
    </>
    )
}

export default Banner
