import React, { useContext, useEffect } from 'react'
import { ProductContext } from '../context/ProductContext'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ViewProduct.css'
import logoVisa from '../assets/visa.png';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom'; // Import for navigation link
import { useNavigate } from 'react-router-dom';

export const ViewProduct = () => {

  const { productView } = useContext( ProductContext );
  const { selectedProduct, setSelectedProduct } = useContext( ProductContext );
  const navigate = useNavigate();
  const [ details, setDetails ] = useState({})
  const [ id, setId ] = useState(''); 
  const [ name, setName ] = useState('');
  const [ description, setDescription]  = useState('');
  const [ valueTotal, setValueTotal]  = useState('');
  const [ size , setSize]  = useState('');
  const [ available, setAvailable]  = useState('');
  const [ credit , setCredit]  = useState('');
  const { setShowLogo } = useContext ( ProductContext ); 

  const [ images, setImages ]  = useState([]);
  const [ imageShow, setImageShow ]  = useState([]);

  const [ idSelected, setIdSelected ] = useState(0);

  const arrayProductSelected = (idSelected) => {
    const resposeProduct = JSON.parse(localStorage.getItem('selectedProduct'));
    try{
      resposeProduct.map((productList)=>{
        if(idSelected == productList._id){
          console.log(productList);
          console.log(productList.images);
          setId(productList._id)
          setDetails(productList);
          setName(productList.name)
          setDescription(productList.description)
          setValueTotal(productList.value_total)
          setAvailable(productList.available)
          setImages(productList.images);
          setImageShow(productList.images[0].image)
          setSize(productList.size)
          setCredit(productList.credit)

        }
      })
    }catch(err){
      navigate("/")
    }
  } 

  useEffect(()=>{
    setShowLogo(true);
    try{
      let idTry = productView.product?._id;
      setIdSelected(idTry)
      localStorage.setItem('idSelected',idTry)
      arrayProductSelected(idTry);
    }catch(err){
      let value = localStorage.getItem('idSelected');
      setIdSelected(value);
      arrayProductSelected(value);
    }
  },[]);

  const handleDataUpdate = (id) => {
    const responseProduct = JSON.parse(localStorage.getItem('selectedProduct'));

    setSelectedProduct((selectedProduct) => {
      try{
            const updatedSelectedProduct = responseProduct.filter((productSelected) =>
      productSelected._id === id ? productSelected : null
    );
    let exist = 0;
    selectedProduct.map((productSelected)=>{
      if(productSelected._id == id){
        exist = 1;
      }
    })
      if (exist == 0 ) {
          return [...selectedProduct, updatedSelectedProduct[0]];
        } else {
          return selectedProduct;
        }
      }catch(err){
        navigate("/")
      }
    });
  };

  return (
    <div className='containerProduct'>
        <Row className="menu-cabecera">
          <Col md={1} xs={3}><button className='buttonNone home-link' onClick={ ()=>navigate("/")}>Home / </button></Col>
          <Col md={3} xs={7}><a className='marginleft30 home-name'>{name}</a></Col>
        </Row>
        <Container>
            <Row className='marginbottom60mob '>
              <Col md={1} className='scroll-product desktop'>
              {images.length > 0 ? (
                images.map((item) => (
                  <div className='img-margin' onClick={()=>{
                    setImageShow(item.image)
                  }}>
                    <img className='img-small' src={item.image} alt={item.altText || ''} key={item.id || Math.random()} /> 
                  </div>
                ))
              ) : (
                <p>No images found.</p>
              )}
              </Col>
              <Col md={1} className='scroll-product mobile'>
                <div className='container-view'>
                    <p className='font-view'>Deslice hacia abajo para ver más</p>
                  </div>
              {images.length > 0 ? (
                images.map((item) => (
                  <div className='img-margin'>
                    <img className='img-small-mobile' src={item.image} alt={item.altText || ''} key={item.id || Math.random()} /> 
                  </div>
                ))
              ) : (
                <p>No images found.</p>
              )}
              </Col>
              <Col md={5} className='desktop' style={{position:'relativo'}}>
                    {images.length > 0 ? (<img className='img-medium' src={imageShow}></img>)
                    : null}
                    {valueTotal > 39000
                    ? <div className='container-free position'>
                        <p className='label-free'>ENVÍO GRATIS</p>
                    </div>
                    :null}
              </Col>
              <Col md={6}>
                <div className='container-details'>
                  <p className='label-title'>{name}</p>
                  <p className='label-price-view'>{valueTotal.toLocaleString('es-CL', {
                      style: 'currency',
                      currency: 'CLP',
                    })
                    }</p>
                  {credit
                  ? <p className='margin-top-less'>Hasta 6 cuotas de {Math.round(valueTotal/6).toLocaleString('es-CL', {
                  style: 'currency',
                  currency: 'CLP',
                })
                } sin interés</p>
                  : null}
                  <p className='label-description'>{description}</p>
                 <p className='label-available'>
                    {available === "Disponible" ? (
                      <> {/* Fragment for better readability */}
                        <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                        </svg>
                        Disponible
                      </>
                    ) : (
                      <> {/* Fragment for better readability */}
                       <svg className='svg-icon-x' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
                        No Disponible
                      </>
                    )}
                    {' '} {/* Add a space after the conditional rendering */}
                    talla {size}
                  </p>

                  <div className='container-button'> 
                      <Row>
                        <Col md={12} xs={12}>
                          <button className={available === "Disponible" ? 'btnAdd' : 'btnAdd btnAddDisabled'}
                            disabled={available === "Disponible" ? false : true}
                            onClick={() => handleDataUpdate(id)}
                          >AGREGAR AL CARRO DE COMPRA</button>
                        </Col>
                        <Col md={12} xs={12}>
                            <button className='btnAddFavorite'
                              onClick={() => null}
                            disabled>AGREGAR A FAVORITO</button>
                        </Col>
                      </Row>
                  </div>
                </div>
              </Col>
            </Row>
        </Container>
    </div>
  )
}
