import { useState } from "react"
import { ProductContext } from "./ProductContext"

export const ProductProvider = ({ children }) => {
    const [ productAll, setProductAll ] = useState([]);
    const [ productView, setProductView ] = useState();
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [ showNavBar, setNavBar ] = useState();
    const [ filterExist, setFilterExist ] = useState();
    const [ showLogo, setShowLogo ] = useState();
    const [ showMenuMob , setshowMenuMob ] = useState();
    const [ textBanner , setTextBanner ] = useState('');

    return(
        <ProductContext.Provider value= {{ productView, setProductView, selectedProduct, setSelectedProduct, showNavBar, setNavBar,
        productAll, setProductAll, filterExist, setFilterExist,
        showLogo, setShowLogo, showMenuMob , setshowMenuMob, textBanner , setTextBanner }}>
            {children}
        </ProductContext.Provider>
    )
}