export const products1 = [
  {
    id: 1,
    imgSrc: "/images/products/orange-1.jpg",
    imgHoverSrc: "/images/products/white-1.jpg",
    title: "Ribbed Tank Top",
    price: 16.95,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/orange-1.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-1.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-1.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "On Sale"],
    brand: "Ecomus",
    isAvailable: true,
  },
  {
    id: 2,
    imgSrc: "/images/products/brown.jpg",
    imgHoverSrc: "/images/products/purple.jpg",
    title: "Ribbed Modal T-shirt",
    price: 18.95,
    colors: [
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown.jpg",
      },
      {
        name: "Light Purple",
        colorClass: "bg_purple",
        imgSrc: "/images/products/purple.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/green.jpg",
      },
    ],
    sizes: ["M", "L", "XL"],
    countdown: { time: 1007500, labels: "d :,h :,m :,s" },
    filterCategories: ["Best seller", "New arrivals"],
    brand: "M&H",
    isAvailable: false,
  },
  {
    id: 3,
    imgSrc: "/images/products/white-3.jpg",
    imgHoverSrc: "/images/products/white-4.jpg",
    title: "Oversized Printed T-shirt",
    price: 10.0,
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "On Sale"],
    brand: "M&H",
    isAvailable: true,
  },
  {
    id: 4,
    imgSrc: "/images/products/white-2.jpg",
    imgHoverSrc: "/images/products/pink-1.jpg",
    title: "Oversized Printed T-shirt",
    price: 16.95,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-2.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_purple",
        imgSrc: "/images/products/pink-1.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-2.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "On Sale"],
    brand: "M&H",
    isAvailable: true,
  },
  {
    id: 5,
    imgSrc: "/images/products/brown-2.jpg",
    imgHoverSrc: "/images/products/brown-3.jpg",
    title: "V-neck Linen T-shirt",
    price: 14.95,
    colors: [
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-2.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-5.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Ecomus",
    isAvailable: true,
  },
  {
    id: 6,
    imgSrc: "/images/products/light-green-1.jpg",
    imgHoverSrc: "/images/products/light-green-2.jpg",
    title: "Loose Fit Sweatshirt",
    price: 10.0,
    colors: [
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/light-green-1.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-3.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue.jpg",
      },
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/dark-blue.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-6.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/light-grey.jpg",
      },
    ],
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Ecomus",
    isAvailable: true,
  },
  {
    id: 7,
    imgSrc: "/images/products/black-4.jpg",
    imgHoverSrc: "/images/products/black-5.jpg",
    title: "Regular Fit Oxford Shirt",
    price: 10.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-4.jpg",
      },
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/dark-blue-2.jpg",
      },
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/beige.jpg",
      },
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-7.jpg",
      },
    ],
    sizes: ["S", "M", "L"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Ecomus",
    isAvailable: false,
  },
  {
    id: 8,
    imgSrc: "/images/products/white-8.jpg",
    imgHoverSrc: "/images/products/black-6.jpg",
    title: "Stylish T-shirt",
    price: 12.0,

    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Ecomus",
    isAvailable: false,
  },
  {
    id: 9,
    price: 14.95,
    sizes: ["M", "L", "XL"],
    colors: [
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-4.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-8.jpg",
      },
    ],

    imgSrc: "/images/products/brown-4.jpg",
    imgHoverSrc: "/images/products/black-8.jpg",

    title: "Patterned scarf",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Ecomus",
    isAvailable: true,
  },
  {
    id: 10,
    price: 18.95,
    sizes: ["S", "M", "L"],
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-9.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-9.jpg",
      },
    ],

    imgSrc: "/images/products/black-9.jpg",
    imgHoverSrc: "/images/products/black-10.jpg",

    title: "Slim Fit Fine-knit Turtleneck Sweater",
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
    brand: "Ecomus",
    isAvailable: true,
  },
  {
    id: 11,
    price: 18.95,
    sizes: ["S", "M", "L"],
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/grey-2.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/pink-2.jpg",
      },
      {
        name: "Light Pink",
        colorClass: "bg_light-pink",
        imgSrc: "/images/products/light-pink.jpg",
      },
    ],

    imgSrc: "/images/products/grey-2.jpg",
    imgHoverSrc: "/images/products/grey.jpg",

    title: "Slim Fit Fine-knit Turtleneck Sweater",
    filterCategories: ["Best seller", "New arrivals"],
    brand: "Ecomus",
    isAvailable: true,
  },
  {
    id: 12,
    price: 18.95,
    sizes: ["S", "M", "L"],

    imgSrc: "/images/products/black-11.jpg",
    imgHoverSrc: "/images/products/black-12.jpg",

    title: "Slim Fit Fine-knit Turtleneck Sweater",
    filterCategories: ["Best seller", "On Sale"],
    brand: "Ecomus",
    isAvailable: true,
  },
];
export const products2 = [
  {
    id: 13,
    imgSrc: "/images/products/black-13.jpg",
    imgHoverSrc: "/images/products/brown-5.jpg",
    title: "Shopper",
    price: 120.95,
    colors: [
      {
        name: "Black",
        bgClass: "bg_dark",
        imgSrc: "/images/products/black-13.jpg",
      },
      {
        name: "Brown",
        bgClass: "bg_brown",
        imgSrc: "/images/products/brown-5.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 14,
    imgSrc: "/images/products/light-brown.jpg",
    imgHoverSrc: "/images/products/light-green-3.jpg",
    title: "Large hair claw",
    price: 8.95,
    colors: [
      {
        name: "Light Brown",
        bgClass: "bg_light-brown",
        imgSrc: "/images/products/light-brown.jpg",
      },
      {
        name: "Light Green",
        bgClass: "bg_light-green",
        imgSrc: "/images/products/light-green-3.jpg",
      },
      {
        name: "Orange",
        bgClass: "bg_orange",
        imgSrc: "/images/products/orange-2.jpg",
      },
    ],
    sizes: ["M", "L", "XL"],
    countdown: { time: 1007500, labels: "d :,h :,m :,s" },
  },
  {
    id: 15,
    imgSrc: "/images/products/light-blue-2.jpg",
    imgHoverSrc: "/images/products/black-14.jpg",
    title: "Large hair claw",
    price: 8.95,
    colors: [
      {
        name: "Light Blue",
        bgClass: "bg_light-blue sold-out",
        imgSrc: "/images/products/light-blue-2.jpg",
      },
      {
        name: "Black",
        bgClass: "bg_dark",
        imgSrc: "/images/products/black-14.jpg",
      },
      {
        name: "White",
        bgClass: "bg_white",
        imgSrc: "/images/products/white-10.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 16,
    imgSrc: "/images/products/brown-6.jpg",
    imgHoverSrc: "/images/products/brown-7.jpg",
    title: "Cat Eye Sunglasses",
    price: 12.95,
    colors: [],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 17,
    imgSrc: "/images/products/brown-8.jpg",
    imgHoverSrc: "/images/products/black-15.jpg",
    title: "Block-heeled Mary Janes",
    price: 139.95,
    colors: [
      {
        name: "Brown",
        bgClass: "bg_brown",
        imgSrc: "/images/products/brown-8.jpg",
      },
      {
        name: "Black",
        bgClass: "bg_white",
        imgSrc: "/images/products/black-15.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 18,
    imgSrc: "/images/products/light-green-4.jpg",
    imgHoverSrc: "/images/products/light-green-5.jpg",
    title: "Frida - Misty Jade",
    price: 130.95,
    colors: [],
  },
  {
    id: 19,
    imgSrc: "/images/products/brown-9.jpg",
    imgHoverSrc: "/images/products/brown-10.jpg",
    title: "Grace - Brown",
    price: 130.95,
    colors: [],
    sizes: ["S", "M", "L"],
  },
  {
    id: 20,
    imgSrc: "/images/products/white-11.jpg",
    imgHoverSrc: "/images/products/light-blue-3.jpg",
    title: "Mini Headset Wired & Wireless",
    price: 235.95,
    colors: [
      {
        name: "White",
        bgClass: "bg_white",
        imgSrc: "/images/products/white-11.jpg",
      },
      {
        name: "Light Blue",
        bgClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue-3.jpg",
      },
      {
        name: "Pink",
        bgClass: "bg_pink",
        imgSrc: "/images/products/pink-3.jpg",
      },
      {
        name: "Black",
        bgClass: "bg_dark",
        imgSrc: "/images/products/black-16.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
];
export const products3 = [
  {
    id: 21,

    imgSrc: "/images/products/light-blue-2.jpg",
    imgHoverSrc: "/images/products/black-14.jpg",
    title: "Cotton Twill Cap",
    price: 26.95,
    colors: [
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue-2.jpg",
        soldOut: true,
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-14.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-10.jpg",
      },
    ],
  },
  {
    id: 22,

    imgSrc: "/images/products/beige-2.jpg",
    imgHoverSrc: "/images/products/beige-3.jpg",
    title: "Cotton jersey top",
    price: 7.95,
    colors: [
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/beige-2.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-17.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue-3.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-12.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    countdown: { time: 1007500, labels: "d :,h :,m :,s" },
  },
];

export const galleryItems = [
  {
    id: 23,
    imgSrc: "/images/shop/gallery/gallery-7.jpg",
    imgAlt: "image-gallery",
    imgWidth: 400,
    imgHeight: 400,
    title: "demo title",
    price: 29.66,
    delay: "0s",
    tooltip: "Quick Add",
  },
  {
    id: 24,
    imgSrc: "/images/shop/gallery/gallery-3.jpg",
    imgAlt: "image-gallery",
    imgWidth: 400,
    imgHeight: 400,
    title: "demo title",
    price: 29.66,
    delay: ".1s",
    tooltip: "Quick Add",
  },
  {
    id: 25,
    imgSrc: "/images/shop/gallery/gallery-5.jpg",
    imgAlt: "image-gallery",
    imgWidth: 400,
    imgHeight: 401,
    title: "demo title",
    price: 29.66,
    delay: ".2s",
    tooltip: "Quick Add",
  },
  {
    id: 26,
    imgSrc: "/images/shop/gallery/gallery-8.jpg",
    imgAlt: "image-gallery",
    imgWidth: 400,
    imgHeight: 400,
    title: "demo title",
    price: 29.66,
    delay: ".3s",
    tooltip: "View product",
    detail: true,
  },
  {
    id: 27,
    imgSrc: "/images/shop/gallery/gallery-6.jpg",
    imgAlt: "image-gallery",
    imgWidth: 400,
    imgHeight: 400,
    title: "demo title",
    price: 29.66,
    delay: ".4s",
    detail: true,
    tooltip: "View product",
  },
];

export const products4 = [
  {
    id: 28,
    imgSrc: "/images/shop/products/hmgoepprod2.jpg",
    imgWidth: 713,
    imgHeight: 1070,
    title: "Patterned scarf",
    price: 15.0,
    options: [
      "Beige / S",
      "Beige / M",
      "Beige / L",
      "Beige / XL",
      "Black / S",
      "Black / M",
      "Black / L",
      "Black / XL",
      "Blue / S",
      "Blue / M",
      "Blue / L",
      "Blue / XL",
      "White / S",
      "White / M",
      "White / L",
      "White / XL",
    ],
  },
  {
    id: 29,
    imgSrc: "/images/shop/products/p-d3.png",
    imgWidth: 713,
    imgHeight: 995,
    title: "Ribbed modal T-shirt",
    price: 8.0,
    compareAtPrice: "$10.00",
    options: [
      "Beige / S",
      "Beige / M",
      "Beige / L",
      "Beige / XL",
      "Black / S",
      "Black / M",
      "Black / L",
      "Black / XL",
      "Blue / S",
      "Blue / M",
      "Blue / L",
      "Blue / XL",
      "White / S",
      "White / M",
      "White / L",
      "White / XL",
    ],
  },
  {
    id: 30,
    imgSrc: "/images/shop/products/p-d1.png",
    imgWidth: 770,
    imgHeight: 1075,
    title: "Cotton jersey top",
    price: 8.0,
    compareAtPrice: "$10.00",
    options: [
      "Beige / S",
      "Beige / M",
      "Beige / L",
      "Beige / XL",
      "Black / S",
      "Black / M",
      "Black / L",
      "Black / XL",
      "Blue / S",
      "Blue / M",
      "Blue / L",
      "Blue / XL",
      "White / S",
      "White / M",
      "White / L",
      "White / XL",
    ],
  },
];

export const products5 = [
  {
    id: 31,
    imgSrc: "/images/products/skincare-1.jpg",
    imgHoverSrc: "/images/products/skincare-2.jpg",
    title: "Natural Moisturizing Factors",
    price: 32.0,
    sizes: ["30ml", "60ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
  {
    id: 32,
    imgSrc: "/images/products/skincare-3.jpg",
    imgHoverSrc: "/images/products/skincare-4.jpg",
    title: "Anhydrous Solution Pore Serum",
    price: 65.0,
    sizes: ["30ml", "60ml"],
    filterCategories: ["Essentials"],
  },
  {
    id: 33,
    imgSrc: "/images/products/skincare-5.jpg",
    imgHoverSrc: "/images/products/skincare-6.jpg",
    title: "Serum for Hair Density",
    price: 65.0,
    sizes: ["40ml", "50ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
  {
    id: 34,
    imgSrc: "/images/products/skincare-7.jpg",
    imgHoverSrc: "/images/products/skincare-8.jpg",
    title: "Mini Confidence in a Cleanser",
    price: 22.0,
    sizes: ["50ml", "80ml"],
    filterCategories: ["Essentials"],
  },
  {
    id: 35,
    imgSrc: "/images/products/skincare-9.jpg",
    imgHoverSrc: "/images/products/skincare-10.jpg",
    title: "Natural Moisturizing Factors",
    price: 52.0,
    oldPrice: "$62.00",
    sizes: ["50ml", "80ml"],
    filterCategories: ["Essentials"],
  },
  {
    id: 36,
    imgSrc: "/images/products/skincare-11.jpg",
    imgHoverSrc: "/images/products/skincare-12.jpg",
    title: "Natural Moisturizing Factors",
    price: 52.0,
    oldPrice: "$62.00",
    sizes: ["50ml", "80ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
  {
    id: 37,
    imgSrc: "/images/products/skincare-13.jpg",
    imgHoverSrc: "/images/products/skincare-14.jpg",
    title: "Mini Skin Perfecting Exfoliant",
    price: 65.0,
    sizes: ["40ml", "50ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
  {
    id: 38,
    imgSrc: "/images/products/skincare-15.jpg",
    imgHoverSrc: "/images/products/skincare-16.jpg",
    title: "Mini Skin Perfecting Exfoliant",
    price: 65.0,
    sizes: ["40ml", "50ml"],
    filterCategories: ["Essentials", "Gift Sets"],
  },
];
export const galleryItems2 = [
  {
    id: 39,
    src: "/images/shop/gallery/gallery-9.jpg",
    alt: "image-gallery",
    delay: "0s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 40,
    src: "/images/shop/gallery/gallery-10.jpg",
    alt: "image-gallery",
    delay: ".1s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 41,
    src: "/images/shop/gallery/gallery-11.jpg",
    alt: "image-gallery",
    delay: ".2s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 42,
    src: "/images/shop/gallery/gallery-12.jpg",
    alt: "image-gallery",
    delay: ".3s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 43,
    src: "/images/shop/gallery/gallery-13.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 44,
    src: "/images/shop/gallery/gallery-14.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 45,
    src: "/images/shop/gallery/gallery-15.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 46,
    src: "/images/shop/gallery/gallery-16.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 47,
    src: "/images/shop/gallery/gallery-9.jpg",
    alt: "image-gallery",
    delay: "0s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 48,
    src: "/images/shop/gallery/gallery-10.jpg",
    alt: "image-gallery",
    delay: ".1s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 49,
    src: "/images/shop/gallery/gallery-11.jpg",
    alt: "image-gallery",
    delay: ".2s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 50,
    src: "/images/shop/gallery/gallery-12.jpg",
    alt: "image-gallery",
    delay: ".3s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 51,
    src: "/images/shop/gallery/gallery-13.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 52,
    src: "/images/shop/gallery/gallery-14.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 53,
    src: "/images/shop/gallery/gallery-15.jpg",
    alt: "image-gallery",
    delay: ".4s",

    title: "demo title",
    price: 29.66,
  },
  {
    id: 54,
    src: "/images/shop/gallery/gallery-16.jpg",
    alt: "image-gallery",
    delay: ".4s",

    price: 29.66,
  },
];

export const products6 = [
  {
    id: 55,
    imgSrc: "/images/products/headphone-1.png",
    imgHoverSrc: "/images/products/headphone-2.png",
    alt: "image-product",
    price: 499.0,
    description: [
      "32 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "4 beamforming microphones",
      "Warranty : 1-year",
    ],

    vendorName: "Gaming X- Seri 1",
    title: "ECOMUS20 BAPE®",
    colors: [
      {
        tooltip: "Light Green",
        bgColor: "bg_light-green",
        image: "/images/products/headphone-1.png",
      },
      {
        tooltip: "Black",
        bgColor: "bg_dark",
        image: "/images/products/headphone-2.png",
      },
      {
        tooltip: "White",
        bgColor: "bg_white",
        image: "/images/products/headphone-3.png",
      },
    ],
    onSale: "New !",
    colorbg: "colorbg-light-green",
  },
  {
    id: 56,
    imgSrc: "/images/products/headphone-4.png",
    imgHoverSrc: "/images/products/headphone-5.png",
    alt: "image-product",
    price: 384.0,
    description: [
      "28 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "3 beamforming microphones",
      "Warranty : 3-year",
    ],
    populer: true,

    vendorName: "Gaming X- Seri 1",
    title: "MG20 BAPE®",
    colors: [
      {
        tooltip: "Blue",
        bgColor: "bg_blue-2",
        image: "/images/products/headphone-4.png",
      },
      {
        tooltip: "Pink",
        bgColor: "bg_purple",
        image: "/images/products/headphone-5.png",
      },
    ],
    onSale: "Best Seller !",
    colorbg: "colorbg-black",
  },
  {
    id: 57,
    imgSrc: "/images/products/headphone-6.png",
    imgHoverSrc: "/images/products/headphone-7.png",
    alt: "image-product",
    price: 384.0,
    description: [
      "30 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "3 beamforming microphones",
      "Warranty : 2-year",
    ],

    vendorName: "Gaming X- Seri 1",
    title: "MW75 BUGATTI",
    colors: [
      {
        tooltip: "Blue",
        bgColor: "bg_blue-2",
        image: "/images/products/headphone-6.png",
      },
      {
        tooltip: "Orange",
        bgColor: "bg_orange-3",
        image: "/images/products/headphone-7.png",
      },
    ],
    onSale: "Recommend !",
    colorbg: "colorbg-radius-20",
  },
  {
    id: 58,
    imgSrc: "/images/products/headphone-1.png",
    imgHoverSrc: "/images/products/headphone-2.png",
    alt: "image-product",
    price: 499.0,
    description: [
      "32 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "4 beamforming microphones",
      "Warranty : 1-year",
    ],

    vendorName: "Gaming X- Seri 1",
    title: "ECOMUS20 BAPE®",
    colors: [
      {
        tooltip: "Light Green",
        bgColor: "bg_light-green",
        image: "/images/products/headphone-1.png",
      },
      {
        tooltip: "Black",
        bgColor: "bg_dark",
        image: "/images/products/headphone-2.png",
      },
      {
        tooltip: "White",
        bgColor: "bg_white",
        image: "/images/products/headphone-3.png",
      },
    ],
    onSale: "New !",
    colorbg: "colorbg-light-green",
  },
  {
    id: 59,
    imgSrc: "/images/products/headphone-4.png",
    imgHoverSrc: "/images/products/headphone-5.png",
    alt: "image-product",
    price: 384.0,
    description: [
      "28 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "3 beamforming microphones",
      "Warranty : 3-year",
    ],
    populer: true,

    vendorName: "Gaming X- Seri 1",
    title: "MG20 BAPE®",
    colors: [
      {
        tooltip: "Blue",
        bgColor: "bg_blue-2",
        image: "/images/products/headphone-4.png",
      },
      {
        tooltip: "Pink",
        bgColor: "bg_purple",
        image: "/images/products/headphone-5.png",
      },
    ],
    onSale: "Best Seller !",
    colorbg: "colorbg-black",
  },
  {
    id: 60,
    imgSrc: "/images/products/headphone-6.png",
    imgHoverSrc: "/images/products/headphone-7.png",
    alt: "image-product",
    price: 384.0,
    description: [
      "30 hours of listening",
      "Bluetooth@ 5.1",
      "30mm beryllium drivers",
      "3 beamforming microphones",
      "Warranty : 2-year",
    ],

    vendorName: "Gaming X- Seri 1",
    title: "MW75 BUGATTI",
    colors: [
      {
        tooltip: "Blue",
        bgColor: "bg_blue-2",
        image: "/images/products/headphone-6.png",
      },
      {
        tooltip: "Orange",
        bgColor: "bg_orange-3",
        image: "/images/products/headphone-7.png",
      },
    ],
    onSale: "Recommend !",
    colorbg: "colorbg-radius-20",
  },
];

export const products7 = [
  {
    id: 61,
    title: "WH-1000XM4 Wireless Headphones",
    imgSrc: "/images/products/headphone-8.png",
    imgHoverSrc: "/images/products/headphone-9.png",
    price: 279.0,
    originalPrice: "$349.00",
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-8.png",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/headphone-9.png",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/headphone-10.png",
      },
    ],
  },
  {
    id: 62,
    title: "LinkBuds S Wireless Earbuds",
    imgSrc: "/images/products/headphone-11.png",
    imgHoverSrc: "/images/products/headphone-12.png",
    price: 199.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-11.png",
      },
      {
        name: "Pink",
        colorClass: "bg_purple",
        imgSrc: "/images/products/headphone-12.png",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-13.png",
      },
    ],
  },
  {
    id: 63,
    title: "Beats Studio Buds",
    imgSrc: "/images/products/headphone-14.png",
    imgHoverSrc: "/images/products/headphone-15.png",
    price: 149.99,
    colors: [
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-14.png",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-15.png",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-16.png",
      },
    ],
  },
  {
    id: 64,
    title: "AirPods Max",
    imgSrc: "/images/products/headphone-17.png",
    imgHoverSrc: "/images/products/headphone-18.png",
    price: 479.0,
    originalPrice: "$549.00",
    colors: [
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/headphone-17.png",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/headphone-18.png",
      },
      {
        name: "Silver",
        colorClass: "bg_silver",
        imgSrc: "/images/products/headphone-19.png",
      },
    ],
  },
  {
    id: 65,
    title: "WH-1000XM4 Wireless Headphones",
    imgSrc: "/images/products/headphone-8.png",
    imgHoverSrc: "/images/products/headphone-9.png",
    price: 279.0,
    originalPrice: "$349.00",
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-8.png",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/headphone-9.png",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/headphone-10.png",
      },
    ],
  },
  {
    id: 66,
    title: "LinkBuds S Wireless Earbuds",
    imgSrc: "/images/products/headphone-11.png",
    imgHoverSrc: "/images/products/headphone-12.png",
    price: 199.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-11.png",
      },
      {
        name: "Pink",
        colorClass: "bg_purple",
        imgSrc: "/images/products/headphone-12.png",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-13.png",
      },
    ],
  },
  {
    id: 67,
    title: "Beats Studio Buds",
    imgSrc: "/images/products/headphone-14.png",
    imgHoverSrc: "/images/products/headphone-15.png",
    price: 149.99,
    colors: [
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-14.png",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-15.png",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-16.png",
      },
    ],
  },
  {
    id: 68,
    title: "AirPods Max",
    imgSrc: "/images/products/headphone-17.png",
    imgHoverSrc: "/images/products/headphone-18.png",
    price: 479.0,
    originalPrice: "$549.00",
    colors: [
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/headphone-17.png",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/headphone-18.png",
      },
      {
        name: "Silver",
        colorClass: "bg_silver",
        imgSrc: "/images/products/headphone-19.png",
      },
    ],
  },
];

export const productSlides = [
  {
    id: 69,
    imgSrc: "/images/products/giftcard-1.jpg",
    title: "AWS eGift Card",
    price: 121,
  },
  {
    id: 70,
    imgSrc: "/images/products/giftcard-2.jpg",
    title: "Bodums eGift Card",
    price: 33,
  },
  {
    id: 71,
    imgSrc: "/images/products/giftcard-3.jpg",
    title: "Jay Jays eGift Card",
    price: 10,
  },
  {
    id: 72,
    imgSrc: "/images/products/giftcard-4.jpg",
    title: "Adventure eGift Card",
    price: 10,
  },
  {
    id: 73,
    imgSrc: "/images/products/giftcard-5.jpg",
    title: "AWS eGift Card",
    price: 121,
  },
  {
    id: 74,
    imgSrc: "/images/products/giftcard-6.jpg",
    title: "Bodums eGift Card",
    price: 33,
  },
  {
    id: 75,
    imgSrc: "/images/products/giftcard-7.jpg",
    title: "Jay Jays eGift Card",
    price: 10,
  },
];

export const products9 = [
  {
    id: 76,
    imgSrc: "/images/products/lamp-black.jpg",
    imgHoverSrc: "/images/products/lamp-black.jpg",
    title: "AJ Wall Sconce",
    price: 982.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/lamp-black.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/lamp-dark-green.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 77,
    imgSrc: "/images/products/furniture-1.jpg",
    imgHoverSrc: "/images/products/furniture-1.jpg",
    title: "Brasilia Lounge Chair",
    price: 982.0,
    colors: [],
    sizes: [], // No sizes provided
  },
  {
    id: 78,
    imgSrc: "/images/products/furniture-2.jpg",
    imgHoverSrc: "/images/products/furniture-3.jpg",
    title: "Lina Swivel Chair",
    price: 3600.0,
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/furniture-2.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/furniture-3.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 79,
    imgSrc: "/images/products/furniture-6.jpg",
    imgHoverSrc: "/images/products/furniture-7.jpg",
    title: "MR Chaise",
    price: 1999.0,
    colors: [
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/furniture-6.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/furniture-7.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 80,
    imgSrc: "/images/products/furniture-4.jpg",
    imgHoverSrc: "/images/products/furniture-5.jpg",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 1290.0,
    oldPrice: "$1,366.00",
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/furniture-4.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/furniture-5.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 81,
    imgSrc: "/images/products/furniture-8.jpg",
    imgHoverSrc: "/images/products/furniture-9.jpg",
    title: "Scissor Chair",
    price: 2755.0,
    colors: [
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/furniture-8.jpg",
      },
      {
        name: "Navy",
        colorClass: "bg_navy",
        imgSrc: "/images/products/furniture-9.jpg",
      },
    ],
    sizes: [], // No sizes provided
  },
  {
    id: 82,
    imgSrc: "/images/products/furniture-10.jpg",
    imgHoverSrc: "/images/products/furniture-11.jpg",
    title: "Soborg Model 3052 Dining Chair",
    price: 1356.0,
    oldPrice: "$1,695.00",
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/furniture-10.jpg",
      },
      {
        name: "Copper",
        colorClass: "bg_copper",
        imgSrc: "/images/products/furniture-11.jpg",
      },
    ],
    sizes: [], // No sizes provided
    onSale: true,
    saleLabel: "Pre-Order",
  },
  {
    id: 83,
    imgSrc: "/images/products/furniture-12.jpg",
    imgHoverSrc: "/images/products/furniture-13.jpg",
    title: "Product Name Here", // Replace with actual product title
    price: 1356.0,
    colors: [
      {
        name: "Color Name",
        colorClass: "color_class",
        imgSrc: "/images/products/furniture-12.jpg",
      }, // Replace with actual color details
      {
        name: "Color Name",
        colorClass: "color_class",
        imgSrc: "/images/products/furniture-13.jpg",
      }, // Replace with actual color details
    ],
    sizes: [], // No sizes provided
  },
];

export const products10 = [
  {
    id: 84,
    imgSrc: "/images/slider/skateboard-slider-01.jpg",
    alt: "fashion-slideshow",
    title: "Yow Surfskates Christenson Hole Shot Surfskate",
    price: 279.99,
  },
  {
    id: 85,
    imgSrc: "/images/slider/skateboard-slider-02.jpg",
    alt: "fashion-slideshow",
    title: "Element Skateboards Section Complete Skateboard",
    price: 71.99,
  },
  {
    id: 86,
    imgSrc: "/images/slider/skateboard-slider-03.jpg",
    alt: "fashion-slideshow",
    title: "Foundation Skateboards Star & Moon White Complete Skateboard",
    price: 89.99,
  },
];

export const products11 = [
  {
    id: 87,
    title: "Habitat Skateboards Ellipse Complete Skateboard",
    price: 9999,
    imgSrc: "/images/products/stakeboard-greenblack.jpg",
    imgHoverSrc: "/images/products/stakeboard-yellowblack.jpg",
    colors: [
      {
        name: "Green Black",
        colorClass: "green-black bg-multiple-color",
        imgSrc: "/images/products/stakeboard-greenblack.jpg",
      },
      {
        name: "Yellow Black",
        colorClass: "yellow-black bg-multiple-color",
        imgSrc: "/images/products/stakeboard-yellowblack.jpg",
      },
    ],
  },
  {
    id: 88,
    title: "Skateboards Thrasher Complete Skateboard",
    price: 89.99,
    imgSrc: "/images/products/stakeboard-blueblack.jpg",
    imgHoverSrc: "/images/products/stakeboard-blueblack.jpg",
    colors: [
      {
        name: "Blue Black",
        colorClass: "blue-black bg-multiple-color",
        imgSrc: "/images/products/stakeboard-blueblack.jpg",
      },
      {
        name: "Blue White",
        colorClass: "blue-white bg-multiple-color",
        imgSrc: "/images/products/stakeboard-bluewhite.jpg",
      },
    ],
  },
  {
    id: 89,
    title: "RAD Wheels Checker Complete Skateboard",
    price: 53.99,
    imgSrc: "/images/products/stakeboard-redblack.jpg",
    imgHoverSrc: "/images/products/stakeboard-redblack.jpg",
    colors: [
      {
        name: "Red Black",
        colorClass: "red-black bg-multiple-color",
        imgSrc: "/images/products/stakeboard-redblack.jpg",
      },
      {
        name: "White Striped",
        colorClass: "white-striped bg-multiple-color",
        imgSrc: "/images/products/stakeboard-whitestriped.jpg",
      },
    ],
  },
  {
    id: 90,
    title: "Element Skateboards Section Complete Skateboard",
    price: 71.99,
    imgSrc: "/images/products/stakeboard-blackwhite.jpg",
    imgHoverSrc: "/images/products/stakeboard-black.jpg",
    colors: [],
  },
  {
    id: 91,
    title: "Foundation Skateboards Star & Moon White Complete Skateboard",
    price: 89.99,
    imgSrc: "/images/products/stakeboard-blackwhite2.jpg",
    imgHoverSrc: "/images/products/stakeboard-black.jpg",
    colors: [],
  },
  {
    id: 92,
    title:
      "Globe Skateboards Blazer XL Coconut / Lime Cruiser Complete Skateboard",
    price: 138.99,
    imgSrc: "/images/products/stakeboard-lightgreen.jpg",
    imgHoverSrc: "/images/products/stakeboard-orange2.jpg",
    colors: [
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/stakeboard-lightgreen.jpg",
      },
    ],
  },
];

export const products12 = [
  {
    id: 93,
    imgSrc: "/images/products/playing-card-1.jpg",
    imgHoverSrc: "/images/products/playing-card-2.jpg",
    alt: "image-product",
    width: 533,
    height: 695,
    title: "Thrasher Magazine Playing Cards",
    price: 9.99,
  },
  {
    id: 94,
    imgSrc: "/images/products/skateboard-grind-rail-white.jpg",
    imgHoverSrc: "/images/products/skateboard-grind-rail-light-green.jpg",
    alt: "image-product",
    width: 533,
    height: 695,
    title: "OC Ramps 8 Foot Round Glow In The Dark Skateboard Grind Rail",
    price: 299,
    colors: [
      {
        tooltip: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/skateboard-grind-rail-white.jpg",
      },
      {
        tooltip: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/skateboard-grind-rail-light-green.jpg",
      },
    ],
  },
  {
    id: 95,
    imgSrc: "/images/products/bubble-gum-yellow.jpg",
    imgHoverSrc: "/images/products/bubble-gum-light-green.jpg",
    alt: "image-product",
    width: 533,
    height: 695,
    title: "Bubble Gum Surf Wax 6 oz.",
    price: 11.99,
    colors: [
      {
        tooltip: "Yellow",
        colorClass: "bg_yellow",
        imgSrc: "/images/products/bubble-gum-yellow.jpg",
      },
      {
        tooltip: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/bubble-gum-light-green.jpg",
      },
    ],
  },
  {
    id: 96,
    imgSrc: "/images/products/sunglasses-black.jpg",
    imgHoverSrc: "/images/products/sunglasses-red.jpg",
    alt: "image-product",
    width: 533,
    height: 695,
    title: "Glassy Sunhaters Leo Premium Sunglasses",
    price: 39.99,
    colors: [
      {
        tooltip: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/sunglasses-black.jpg",
      },
      {
        tooltip: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/sunglasses-blue.jpg",
      },
      {
        tooltip: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/sunglasses-red.jpg",
      },
    ],
  },
  {
    id: 97,
    imgSrc: "/images/products/skateboards-tool-black.jpg",
    imgHoverSrc: "/images/products/skateboards-tool-red.jpg",
    alt: "image-product",
    width: 676,
    height: 882,
    title: "Skateboards Tool",
    price: 29.99,
  },
];

export const products13 = [
  {
    id: 98,
    imgSrc: "/images/products/stroller-baby-brown.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black.jpg",
    title: "Baby Stroller with Ride-Along Board",
    price: 950.0,
    colors: [
      {
        name: "Pastel Brown",
        colorClass: "bg_pastel-brown",
        imgSrc: "/images/products/stroller-baby-brown.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black.jpg",
      },
    ],
  },
  {
    id: 99,
    imgSrc: "/images/products/stroller-baby-black2.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black2.jpg",
    title: "Racing Tricycle Baby Stroller",
    price: 300.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black2.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/stroller-baby-darkgreen.jpg",
      },
    ],
  },
  {
    id: 100,
    imgSrc: "/images/products/stroller-baby-black3.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black3.jpg",
    title: "Stroller Bundle with Black Frame",
    price: 499.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black3.jpg",
      },
      {
        name: "Pastel Brown",
        colorClass: "bg_pastel-brown",
        imgSrc: "/images/products/stroller-baby-brown.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/stroller-baby-pink.jpg",
      },
    ],
  },
  {
    id: 101,
    imgSrc: "/images/products/stroller-baby-yellow.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black4.jpg",
    title: "Stokke X Baby Stroller",
    price: 1199.0,
    colors: [
      {
        name: "Yellow",
        colorClass: "bg_yellow-4",
        imgSrc: "/images/products/stroller-baby-yellow.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black4.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/stroller-baby-grey.jpg",
      },
    ],
  },
  {
    id: 102,
    imgSrc: "/images/products/stroller-baby-black5.jpg",
    imgHoverSrc: "/images/products/stroller-baby-beige.jpg",
    title: "Sand Jogging Baby Stroller",
    price: 399.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/stroller-baby-black5.jpg",
      },
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/stroller-baby-beige.jpg",
      },
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/stroller-baby-darkblue.jpg",
      },
    ],
  },
];
export const products14 = [
  {
    id: 103,
    imgSrc: "/images/products/decor-1.jpg",
    imgHoverSrc: "/images/products/decor-2.jpg",
    title: "Flycatche",
    price: 82.0,
    colors: [
      {
        name: "Light Pink",
        colorClass: "bg_light-pink",
        imgSrc: "/images/products/decor-1.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/decor-3.jpg",
      },
    ],
  },
  {
    id: 104,
    imgSrc: "/images/products/decor-4.jpg",
    imgHoverSrc: "/images/products/decor-4.jpg",
    title: "Candleholder",
    price: 85.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/decor-4.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/decor-5.jpg",
      },
    ],
  },
  {
    id: 105,
    imgSrc: "/images/products/decor-6.jpg",
    imgHoverSrc: "/images/products/decor-7.jpg",
    title: "Standing Polar fox",
    price: 82.0,
    colors: [
      {
        name: "Light Pink",
        colorClass: "bg_light-pink",
        imgSrc: "/images/products/decor-6.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/decor-8.jpg",
      },
    ],
  },
  {
    id: 106,
    imgSrc: "/images/products/decor-9.jpg",
    imgHoverSrc: "/images/products/decor-10.jpg",
    title: "Candleholder gift set brassfox",
    price: 69.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/decor-9.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/decor-11.jpg",
      },
      {
        name: "Gold",
        colorClass: "bg_gold",
        imgSrc: "/images/products/decor-12.jpg",
      },
    ],
  },
  {
    id: 107,
    imgSrc: "/images/products/decor-13.jpg",
    imgHoverSrc: "/images/products/decor-14.jpg",
    title: "Polar fox",
    price: 62.0,
    colors: [
      {
        name: "Light Pink",
        colorClass: "bg_light-pink",
        imgSrc: "/images/products/decor-13.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/decor-14.jpg",
      },
    ],
  },
  {
    id: 108,
    imgSrc: "/images/products/decor-15.jpg",
    imgHoverSrc: "/images/products/decor-16.jpg",
    title: "Another Product",
    price: 70.0,
    colors: [
      {
        name: "Color Name",
        colorClass: "bg_color-class",
        imgSrc: "/images/products/decor-15.jpg",
      },
    ],
  },
];

export const products15 = [
  {
    id: 109,
    imgSrc: "/images/collections/electronic-8.png",
    imgHoverSrc: "/images/collections/electronic-9.jpg",
    title: "UltraGlass 2 Treated Screen Protector for iPhone 15 Pro",
    price: 39.99,
  },
  {
    id: 110,
    imgSrc: "/images/collections/electronic-10.png",
    imgHoverSrc: "/images/collections/electronic-11.png",
    title: "Smart Light Switch with Thread",
    price: 49.99,
  },
  {
    id: 111,
    imgSrc: "/images/collections/electronic-12.png",
    imgHoverSrc: "/images/collections/electronic-13.png",
    title: "SoundForm Rise",
    price: 79.99,
    oldPrice: "$100.00",
  },
  {
    id: 112,
    imgSrc: "/images/collections/electronic-14.png",
    imgHoverSrc: "/images/collections/electronic-15.png",
    title: "Wireless On-Ear Headphones for Kids",
    price: 24.99,
    oldPrice: "$34.99",
  },
  {
    id: 113,
    imgSrc: "/images/collections/electronic-16.jpg",
    imgHoverSrc: "/images/collections/electronic-17.jpg",
    title: "3-in-1 Wireless Charger with Official MagSafe Charging 15W",
    price: 127.49,
    oldPrice: "$149.99",
  },
  {
    id: 114,
    imgSrc: "/images/collections/electronic-18.jpg",
    imgHoverSrc: "/images/collections/electronic-19.jpg",
    title: "3-in-1 Wireless Charger for Apple Devices",
    price: 119.99,
  },
];

export const products16 = [
  {
    id: 115,
    imgSrc: "/images/products/setup-gear-1.jpg",
    imgHoverSrc: "/images/products/setup-gear-2.jpg",
    title: "Deltahub Carpio 2.0 Wrist Rest",
    oldPrice: "$54.95",
    price: 40.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-1.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/setup-gear-2.jpg",
      },
    ],
  },
  {
    id: 116,
    imgSrc: "/images/products/setup-gear-3.jpg",
    imgHoverSrc: "/images/products/setup-gear-4.jpg",
    title: "Topo Designs Medium Accessory Bag",
    oldPrice: "$33.00",
    price: 25.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/setup-gear-3.jpg",
      },
      {
        name: "Navy",
        colorClass: "bg_navy",
        imgSrc: "/images/products/setup-gear-5.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-6.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/setup-gear-4.jpg",
      },
    ],
  },
  {
    id: 117,
    imgSrc: "/images/products/setup-gear-7.jpg",
    imgHoverSrc: "/images/products/setup-gear-8.jpg",
    title: "Rumpl Original Puffy 1-Person Blanket",
    oldPrice: "$149.00",
    price: 97.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-7.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/setup-gear-8.jpg",
      },
    ],
  },
  {
    id: 118,
    imgSrc: "/images/products/setup-gear-9.jpg",
    imgHoverSrc: "/images/products/setup-gear-10.jpg",
    title: 'Bellroy 16" Classic Backpack Plus 24L',
    oldPrice: "$289.00",
    price: 190.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-9.jpg",
      },
      {
        name: "Navy",
        colorClass: "bg_navy",
        imgSrc: "/images/products/setup-gear-11.jpg",
      },
      {
        name: "Copper",
        colorClass: "bg_copper",
        imgSrc: "/images/products/setup-gear-10.jpg",
      },
    ],
  },
  {
    id: 119,
    imgSrc: "/images/products/setup-gear-12.jpg",
    imgHoverSrc: "/images/products/setup-gear-13.png",
    title: "Bellroy Note Sleeve Wallet",
    price: 139.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-12.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/setup-gear-14.jpg",
      },
      {
        name: "Navy",
        colorClass: "bg_navy",
        imgSrc: "/images/products/setup-gear-13.png",
      },
    ],
  },
  {
    id: 120,
    imgSrc: "/images/products/setup-gear-15.jpg",
    imgHoverSrc: "/images/products/setup-gear-16.jpg",
    title: "Tokyo Folio 8",
    price: 119.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-15.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/setup-gear-16.jpg",
      },
    ],
  },
  {
    id: 121,
    imgSrc: "/images/products/setup-gear-17.jpg",
    imgHoverSrc: "/images/products/setup-gear-18.jpg",
    title: "Bellroy Laptop Sleeve",
    price: 99.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/setup-gear-17.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/setup-gear-18.jpg",
      },
    ],
  },
];

export const products17 = [
  {
    id: 122,
    imgSrc: "/images/products/dog-1.jpg",
    imgHoverSrc: "/images/products/dog-2.jpg",
    title: "Cushioned Dog Harness Moonstone",
    price: 16.95,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/dog-1.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/dog-2.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/dog-3.jpg",
      },
    ],
  },
  {
    id: 123,
    imgSrc: "/images/products/dog-4.jpg",
    imgHoverSrc: "/images/products/dog-5.jpg",
    title: "Stripe Tee Rust and Ivory",
    price: 41.0,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/dog-4.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/dog-5.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/dog-6.jpg",
      },
    ],
  },
  {
    id: 124,
    imgSrc: "/images/products/dog-7.jpg",
    imgHoverSrc: "/images/products/dog-8.jpg",
    title: "Hooman Nosework Toy Love",
    price: 17.0,
    colors: [
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/dog-7.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/dog-8.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/dog-9.jpg",
      },
    ],
  },
  {
    id: 125,
    imgSrc: "/images/products/dog-10.jpg",
    imgHoverSrc: "/images/products/dog-11.jpg",
    title: "The Scot Collar Mint",
    price: 56.0,
    colors: [
      {
        name: "Yellow",
        colorClass: "bg_yellow",
        imgSrc: "/images/products/dog-10.jpg",
      },
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/dog-11.jpg",
      },
    ],
  },
  {
    id: 126,
    imgSrc: "/images/products/dog-12.jpg",
    imgHoverSrc: "/images/products/dog-13.jpg",
    title: "Buffalo Bow Tie",
    price: 45.0,
    colors: [],
  },
];

export const products18 = [
  {
    id: 127,
    imgSrc: "/images/products/kitchen-wear-1.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-2.jpg",
    title: "Fry Pan",
    price: 16.95,
    colors: [
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/kitchen-wear-1.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/kitchen-wear-3.jpg",
      },
    ],
    filterCategories: ["Best seller", "On Sale"],
  },
  {
    id: 128,
    imgSrc: "/images/products/kitchen-wear-4.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-5.jpg",
    title: "Sauce Pan",
    price: 18.95,
    filterCategories: ["Best seller", "New arrivals"],
  },
  {
    id: 129,
    imgSrc: "/images/products/kitchen-wear-6.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-7.jpg",
    title: "Linen Apron",
    price: 10.0,
    colors: [
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/kitchen-wear-6.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/kitchen-wear-8.jpg",
      },
      {
        name: "Orange",
        colorClass: "bg_orange",
        imgSrc: "/images/products/kitchen-wear-9.jpg",
      },
    ],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
  {
    id: 130,
    imgSrc: "/images/products/kitchen-wear-10.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-11.jpg",
    title: "Whistling Tea Kettle",
    price: 16.95,
    onSale: true,
    filterCategories: ["Best seller", "On Sale"],
  },
  {
    id: 131,
    imgSrc: "/images/products/kitchen-wear-12.jpg",
    imgHoverSrc: "/images/products/kitchen-wear-13.jpg",
    title: "Food Storage Set",
    price: 16.95,
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
];

export const products19 = [
  {
    id: 132,
    imgSrc: "/images/item/lookbook-item-1.jpg",
    imgAlt: "",
    title: "Fry Pan",
    price: 150.0,
    variants: ["Dark Blue", "Light Grey"],
  },
  {
    id: 133,
    imgSrc: "/images/item/lookbook-item-2.jpg",
    imgAlt: "",
    title: "Whistling Tea Kettle",
    compareAtPrice: "$105.00",
    price: 95.0,
  },
  {
    id: 134,
    imgSrc: "/images/item/lookbook-item-3.jpg",
    imgAlt: "",
    title: "Linen Apron",
    price: 95.0,
    variants: ["Dark Blue", "Cream", "Orange"],
  },
];

export const products20 = [
  {
    id: 135,
    imgSrc: "/images/products/phonecase-1.jpg",
    imgHoverSrc: "/images/products/phonecase-2.jpg",
    title: "Almond Latte - Cute iPhone Case",
    price: 30.0,
  },
  {
    id: 136,
    imgSrc: "/images/products/phonecase-3.jpg",
    imgHoverSrc: "/images/products/phonecase-4.jpg",
    title: "Full Glam - Beige iPhone Case",
    price: 30.0,
  },
  {
    id: 137,
    imgSrc: "/images/products/phonecase-5.jpg",
    imgHoverSrc: "/images/products/phonecase-6.jpg",
    title: "Emerald Pool - Elegant iPhone case",
    price: 30.0,
  },
  {
    id: 138,
    imgSrc: "/images/products/phonecase-7.jpg",
    imgHoverSrc: "/images/products/phonecase-8.jpg",
    title: "Femme Fatale - iPhone Case",
    price: 30.0,
  },
  {
    id: 139,
    imgSrc: "/images/products/phonecase-9.jpg",
    imgHoverSrc: "/images/products/phonecase-10.jpg",
    title: "Cherrybomb - iPhone Case",
    price: 30.0,
  },
  {
    id: 140,
    imgSrc: "/images/products/phonecase-11.jpg",
    imgHoverSrc: "/images/products/phonecase-12.jpg",
    title: "Trouble - iPhone Case",
    price: 30.0,
  },
];

export const products21 = [
  {
    id: 141,
    imgSrc: "/images/products/paddle-boards-7.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1-small.jpg",
    vendor: "Ecomus X- Class",
    title: "CRUISER SERIES - X",
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-1-small.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2-small.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3-small.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4-small.jpg",
      },
    ],
    price: 138.0,
    features: [
      "Build: Inflatable",
      "Skill: Beginner-Advanced",
      "Use: All Around",
      "Weight: 19.8 lbs",
      "Sizes: 10’6″x32″x6″",
      "Warranty: 1-year",
    ],
    new: true,
  },
  {
    id: 142,
    imgSrc: "/images/products/paddle-boards-6.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1-small.jpg",
    vendor: "Ecomus X- Class",
    title: "PRO SERIES - X",
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-1-small.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2-small.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3-small.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4-small.jpg",
      },
    ],
    price: 138.0,
    features: [
      "Build: Inflatable",
      "Skill: Beginner-Advanced",
      "Use: All Around",
      "Weight: 19.8 lbs",
      "Sizes: 10’6″x32″x6″",
      "Warranty: 2-year",
    ],
    populer: true,
  },
  {
    id: 143,
    imgSrc: "/images/products/paddle-boards-5.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1-small.jpg",
    vendor: "Ecomus X- Class",
    title: "TOURER SERIES - X",
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-1-small.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2-small.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3-small.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4-small.jpg",
      },
    ],
    price: 138.0,
    features: [
      "Build: Inflatable",
      "Skill: Beginner-Advanced",
      "Use: All Around",
      "Weight: 19.8 lbs",
      "Sizes: 10’6″x32″x6″",
      "Warranty: 3-year",
    ],
    recommend: true,
  },
];

export const products22 = [
  {
    id: 144,
    imgSrc: "/images/products/paddle-boards-2.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1.jpg",
    title: "ALL AROUND 11' ULTRA™",
    price: 138.0,
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-1.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4.jpg",
      },
    ],
  },
  {
    id: 145,
    imgSrc: "/images/products/paddle-boards-9.jpg",
    imgHoverSrc: "/images/products/paddle-boards-10.jpg",
    title: "ISUP PADDLE BOARD",
    price: 138.0,
    colors: [],
  },
  {
    id: 146,
    imgSrc: "/images/products/paddle-boards-11.jpg",
    imgHoverSrc: "/images/products/paddle-boards-12.jpg",
    title: "KAYAK ISUP PADDLE ADAPTER",
    price: 138.0,
    colors: [],
  },
  {
    id: 147,
    imgSrc: "/images/products/paddle-boards-8.jpg",
    imgHoverSrc: "/images/products/paddle-boards-1.jpg",
    title: "ALL AROUND 11' ULTRA™",
    price: 138.0,
    colors: [
      {
        name: "blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/paddle-boards-8.jpg",
      },
      {
        name: "warm tan",
        colorClass: "bg_yellow-6",
        imgSrc: "/images/products/paddle-boards-2.jpg",
      },
      {
        name: "white",
        colorClass: "bg_white",
        imgSrc: "/images/products/paddle-boards-3.jpg",
      },
      {
        name: "marble blue",
        colorClass: "bg_green-5",
        imgSrc: "/images/products/paddle-boards-4.jpg",
      },
    ],
  },
  {
    id: 148,
    imgSrc: "/images/products/paddle-boards-9.jpg",
    imgHoverSrc: "/images/products/paddle-boards-10.jpg",
    title: "ISUP PADDLE BOARD",
    price: 138.0,
    colors: [],
  },
];

export const products23 = [
  {
    id: 149,
    imgSrc: "/images/products/glasses-1.jpg",
    imgHoverSrc: "/images/products/glasses-2.jpg",
    alt: "CAT EYE GLASSES",
    title: "CAT EYE GLASSES",
    price: 12.99,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-1.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-3.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES"],
  },
  {
    id: 150,
    imgSrc: "/images/products/glasses-4.jpg",
    imgHoverSrc: "/images/products/glasses-5.jpg",
    alt: "FASHION GLASSES",
    title: "FASHION GLASSES",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-4.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-6.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES", "BEST SELLERS"],
  },
  {
    id: 151,
    imgSrc: "/images/products/glasses-7.jpg",
    imgHoverSrc: "/images/products/glasses-8.jpg",
    alt: "PERSONALIZED FASHION GLASSES",
    title: "PERSONALIZED FASHION GLASSES",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-7.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-9.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES"],
  },
  {
    id: 152,
    imgSrc: "/images/products/glasses-10.jpg",
    imgHoverSrc: "/images/products/glasses-11.jpg",
    alt: "FASHION EYE GLASSES",
    title: "FASHION EYE GLASSES",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-10.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-12.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES", "BEST SELLERS"],
  },
  {
    id: 153,
    imgSrc: "/images/products/glasses-13.jpg",
    imgHoverSrc: "/images/products/glasses-14.jpg",
    alt: "FASHION EYE GLASSES V2",
    title: "FASHION EYE GLASSES V2",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-13.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-15.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES", "BEST SELLERS"],
  },
  {
    id: 154,
    imgSrc: "/images/products/glasses-16.jpg",
    imgHoverSrc: "/images/products/glasses-17.jpg",
    alt: "FASHION EYE GLASSES V3",
    title: "FASHION EYE GLASSES V3",
    price: 30,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/glasses-16.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/glasses-18.jpg",
      },
    ],
    filterCategories: ["NEW RELEASES", "BEST SELLERS"],
  },
];

export const products24 = [
  {
    id: 155,
    imgSrc: "/images/shop/gallery/gallery-17.jpg",
    alt: "image-gallery",
    wowDelay: "0s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 156,
    imgSrc: "/images/shop/gallery/gallery-18.jpg",
    alt: "image-gallery",
    wowDelay: ".1s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 157,
    imgSrc: "/images/shop/gallery/gallery-19.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 158,
    imgSrc: "/images/shop/gallery/gallery-20.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 159,
    imgSrc: "/images/shop/gallery/gallery-21.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 160,
    imgSrc: "/images/shop/gallery/gallery-22.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 161,
    imgSrc: "/images/shop/gallery/gallery-23.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 162,
    imgSrc: "/images/shop/gallery/gallery-24.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
];

export const products25 = [
  {
    id: 163,
    imgSrc: "/images/products/pod-store-1.jpg",
    imgHoverSrc: "/images/products/pod-store-1.jpg",
    title: "Bound To Things Up T-Shirt",
    price: 48.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-1.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-4.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/pod-store-3.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "On Sale"],
  },
  {
    id: 164,
    imgSrc: "/images/products/pod-store-5.jpg",
    imgHoverSrc: "/images/products/pod-store-6.jpg",
    title: "Empathy Art Print",
    price: 52.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-5.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-6.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/pod-store-7.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
  {
    id: 165,
    imgSrc: "/images/products/pod-store-8.jpg",
    imgHoverSrc: "/images/products/pod-store-9.jpg",
    title: "Lazy Days T-Shirt",
    price: 52.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-8.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-9.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/pod-store-10.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals"],
  },
  {
    id: 166,
    imgSrc: "/images/products/pod-store-11.jpg",
    imgHoverSrc: "/images/products/pod-store-12.jpg",
    title: "Empathy Hoodie",
    price: 52.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-11.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-12.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/pod-store-13.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
  {
    id: 167,
    imgSrc: "/images/products/pod-store-14.jpg",
    imgHoverSrc: "/images/products/pod-store-15.jpg",
    title: "Empathy Crop Tee",
    price: 56.0,
    colors: [
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/pod-store-14.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/pod-store-15.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Best seller", "New arrivals", "On Sale"],
  },
];

export const products26 = [
  {
    id: 168,
    imgSrc: "/images/products/pod-store-16.jpg",
    imgHoverSrc: "/images/products/pod-store-17.png",
    title: "Slasher T-Shirt",
    price: 30.0,
  },
  {
    id: 169,
    imgSrc: "/images/products/pod-store-18.jpg",
    imgHoverSrc: "/images/products/pod-store-19.jpg",
    title: "Santa Sleigher T-Shirt",
    price: 30.0,
  },
  {
    id: 170,
    imgSrc: "/images/products/pod-store-20.jpg",
    imgHoverSrc: "/images/products/pod-store-21.jpg",
    title: "Horror Movies and Chill T-Shirt",
    price: 30.0,
  },
  {
    id: 171,
    imgSrc: "/images/products/pod-store-22.jpg",
    imgHoverSrc: "/images/products/pod-store-23.jpg",
    title: "No You Hang Up T-Shirt",
    price: 30.0,
  },
  {
    id: 172,
    imgSrc: "/images/products/pod-store-24.jpg",
    imgHoverSrc: "/images/products/pod-store-25.jpg",
    title: "Teletubbies T-Shirt",
    price: 30.0,
  },
];

export const products27 = [
  {
    id: 173,
    imgSrc: "/images/products/brown-11.jpg",
    imgHoverSrc: "/images/products/brown-12.jpg",
    title: "Crossover Leggings",
    price: 38.0,
    colors: [
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-11.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-24.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/pink-4.jpg",
      },
      {
        name: "Purple",
        colorClass: "bg_purple",
        imgSrc: "/images/products/purple-2.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 174,
    imgSrc: "/images/products/blue-5.jpg",
    imgHoverSrc: "/images/products/blue-6.jpg",
    title: "Pocket Shorts",
    price: 38.0,
    colors: [
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue-5.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-25.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/red.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-13.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 175,
    imgSrc: "/images/products/white-17.jpg",
    imgHoverSrc: "/images/products/white-18.jpg",
    title: "Ruched Strappy Sports Bra",
    price: 38.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-17.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-2.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-26.jpg",
      },
      {
        name: "Pink",
        colorClass: "bg_pink",
        imgSrc: "/images/products/pink-5.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 176,
    imgSrc: "/images/products/grey-3.jpg",
    imgHoverSrc: "/images/products/grey-4.jpg",
    title: "Vital Seamless 2.0 V Neck Sports Bra",
    price: 38.0,
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/grey-3.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-3.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-27.jpg",
      },
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/light-green-6.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 177,
    imgSrc: "/images/products/white-19.jpg",
    imgHoverSrc: "/images/products/white-20.jpg",
    title: "Adapt Fleck Seamless Long Sleever Crop Top",
    price: 38.0,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-19.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-28.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-14.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-4.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
  },
  {
    id: 178,
    imgSrc: "/images/products/black-29.jpg",
    imgHoverSrc: "/images/products/black-30.jpg",
    title: "Product Title 5",
    price: 38.0,

    sizes: ["S", "M", "L", "XL"],
  },
];

export const products28 = [
  {
    id: 179,
    imgSrc: "/images/products/brown-15.jpg",
    imgHoverSrc: "/images/products/sports-clothing-2.jpg",

    title: "Pocket Shorts",
    price: 38.0,
    colors: [
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-15.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-6.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/sports-clothing.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men", "Women"],
  },
  {
    id: 180,
    imgSrc: "/images/products/sports-clothing-3.jpg",
    imgHoverSrc: "/images/products/sports-clothing-4.jpg",

    title: "Essential Oversized Zip Up Hoodie",
    price: 38.0,
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/sports-clothing-3.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-31.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue-8.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-16.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men"],
  },
  {
    id: 181,
    imgSrc: "/images/products/sports-clothing-6.jpg",
    imgHoverSrc: "/images/products/sports-clothing-5.jpg",

    title: 'Crest 7" Shorts',
    price: 38.0,
    colors: [
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/sports-clothing-6.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/grey-5.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-17.jpg",
      },
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue-5.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men", "Women"],
  },
  {
    id: 182,
    imgSrc: "/images/products/sports-clothing-7.jpg",
    imgHoverSrc: "/images/products/sports-clothing-8.jpg",

    title: "Crest Long Sleeve T-Shirt",
    price: 38.0,
    colors: [
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/sports-clothing-7.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-21.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-18.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-32.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men", "Women"],
  },
  {
    id: 183,
    imgSrc: "/images/products/t-shirt-15.jpg",
    imgHoverSrc: "/images/products/t-shirt-16.jpg",

    title: "Crest T-Shirt",
    price: 38.0,
    colors: [
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/t-shirt-15.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-22.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/green-7.jpg",
      },
      {
        name: "Brown",
        colorClass: "bg_brown",
        imgSrc: "/images/products/brown-19.jpg",
      },
    ],
    sizes: ["S", "M", "L", "XL"],
    filterCategories: ["Men", "Women"],
  },
];

export const products29 = [
  {
    id: 184,
    imgSrc: "/images/products/white-17.jpg",
    title: "Ruched Strappy Sports Bra",
    price: 38.0,
    options: [
      "S / White",
      "S / Green",
      "S / Black",
      "S / Pink",
      "M / White",
      "M / Green",
      "M / Black",
      "M / Pink",
      "L / White",
      "L / Green",
      "L / Black",
      "L / Pink",
      "XL / White",
      "XL / Green",
      "XL / Black",
      "XL / Pink",
    ],
  },
  {
    id: 185,
    imgSrc: "/images/products/blue-5.jpg",
    title: "Pocket Shorts",
    price: 38.0,
    options: [
      "S / Blue",
      "S / Green",
      "S / Black",
      "S / Pink",
      "M / White",
      "M / Green",
      "M / Black",
      "M / Pink",
      "L / White",
      "L / Green",
      "L / Black",
      "L / Pink",
      "XL / White",
      "XL / Green",
      "XL / Black",
      "XL / Pink",
    ],
  },
  {
    id: 186,
    imgSrc: "/images/products/brown-11.jpg",
    title: "Crossover Leggings",
    price: 38.0,
    options: [
      "Brown / S",
      "Beige / M",
      "Black / S",
      "Black / M",
      "Black / L",
      "Black / XL",
      "Blue / S",
      "Blue / M",
      "Blue / L",
      "Blue / XL",
      "White / S",
      "White / M",
      "White / L",
      "White / XL",
    ],
  },
];

export const products30 = [
  {
    id: 187,
    imgSrc: "/images/shop/gallery/activewear-gallery1.jpg",
    alt: "image-gallery",
    wowDelay: "0s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 188,
    imgSrc: "/images/shop/gallery/activewear-gallery2.jpg",
    alt: "image-gallery",
    wowDelay: ".1s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 189,
    imgSrc: "/images/shop/gallery/activewear-gallery3.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 190,
    imgSrc: "/images/shop/gallery/activewear-gallery4.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 191,
    imgSrc: "/images/shop/gallery/activewear-gallery5.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 192,
    imgSrc: "/images/shop/gallery/activewear-gallery6.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
];

export const products31 = [
  {
    id: 193,
    imgSrc: "/images/products/green-handbag-1.jpg",
    imgHoverSrc: "/images/products/green-handbag-2.jpg",
    title: "Amanda - Green",
    price: 138.0,
  },
  {
    id: 194,
    imgSrc: "/images/products/brown-handbag-1.jpg",
    imgHoverSrc: "/images/products/brown-handbag-2.jpg",
    title: "Grace - Brown",
    price: 138.0,
  },
  {
    id: 195,
    imgSrc: "/images/products/pink-handbag-1.jpg",
    imgHoverSrc: "/images/products/pink-handbag-2.jpg",
    title: "Alice Mini - Dusty Rose",
    price: 138.0,
  },
];

export const products32 = [
  {
    id: 196,
    imgSrc: "/images/shop/gallery/handbag-gallery-1.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 197,
    imgSrc: "/images/shop/gallery/handbag-gallery-2.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 198,
    imgSrc: "/images/shop/gallery/handbag-gallery-3.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 199,
    imgSrc: "/images/shop/gallery/handbag-gallery-4.jpg",
    alt: "image-gallery",
    wowDelay: ".5s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 200,
    imgSrc: "/images/shop/gallery/handbag-gallery-5.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 201,
    imgSrc: "/images/shop/gallery/handbag-gallery-2.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: true,
    price: 29.66,
    title: "demo title",
  },
];

export const products33 = [
  {
    id: 202,
    imgSrc: "/images/products/t-shirt.jpg",
    imgHoverSrc: "/images/products/t-shirt-2.jpg",
    title: "Amazing Grace Mineral Wash Premium Tee",
    price: 39.0,
  },
  {
    id: 203,
    imgSrc: "/images/products/t-shirt-3.jpg",
    imgHoverSrc: "/images/products/t-shirt-4.jpg",
    title: "God Behind Bars 'God's Purpose' Sand Tee",
    price: 38.0,
  },
  {
    id: 204,
    imgSrc: "/images/products/t-shirt-7.jpg",
    imgHoverSrc: "/images/products/t-shirt-8.jpg",
    title: "V.3 Mentality Tee",
    price: 39.0,
  },
  {
    id: 205,
    imgSrc: "/images/products/hat.jpg",
    imgHoverSrc: "/images/products/hat-2.jpg",
    title: '"The Pack" Beanie',
    price: 39.0,
  },
  {
    id: 206,
    imgSrc: "/images/products/men-hoodie-3.jpg",
    imgHoverSrc: "/images/products/men-hoodie-4.jpg",
    title: "Finish The Fight Tie Dye Sweatshirt",
    price: 39.0,
  },
  {
    id: 207,
    imgSrc: "/images/products/t-shirt-9.jpg",
    imgHoverSrc: "/images/products/t-shirt-10.jpg",
    title: "Y'all Tee - Black",
    price: 39.0,
  },
  {
    id: 208,
    imgSrc: "/images/products/t-shirt-5.jpg",
    imgHoverSrc: "/images/products/t-shirt-6.jpg",
    title: "Shein Ezwear Shoulder Tee",
    price: 39.0,
  },
  {
    id: 209,
    imgSrc: "/images/products/men-hoodie.jpg",
    imgHoverSrc: "/images/products/men-hoodie-2.jpg",
    title: '"It Is Finished" Bleach Wash Hoodie',
    price: 38.0,
  },
];

export const products34 = [
  {
    id: 210,
    imgSrc: "/images/products/men-hoodie-5.jpg",
    imgHoverSrc: "/images/products/men-hoodie-6.jpg",
    title: "Gratitude Hoodie",
    price: 48.0,
  },
  {
    id: 211,
    imgSrc: "/images/products/t-shirt-5.jpg",
    imgHoverSrc: "/images/products/t-shirt-6.jpg",
    title: "Shein Ezwear Shoulder Tee",
    price: 39.0,
  },
  {
    id: 212,
    imgSrc: "/images/products/t-shirt-11.jpg",
    imgHoverSrc: "/images/products/t-shirt-12.jpg",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
  {
    id: 213,
    imgSrc: "/images/products/t-shirt-13.jpg",
    imgHoverSrc: "/images/products/t-shirt-14.jpg",
    title: "Y'all Tee - Blush",
    price: 38.0,
  },
  {
    id: 214,
    imgSrc: "/images/products/men-hoodie-7.jpg",
    imgHoverSrc: "/images/products/men-hoodie-8.jpg",
    title: "Victory Crewneck",
    price: 38.0,
  },
  {
    id: 215,
    imgSrc: "/images/products/men-hoodie.jpg",
    imgHoverSrc: "/images/products/men-hoodie-2.jpg",
    title: '"It Is Finished" Bleach Wash Hoodie',
    price: 38.0,
  },
];

export const products35 = [
  {
    id: 216,
    imgSrc: "/images/shop/gallery/tee-gallery-1.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 217,
    imgSrc: "/images/shop/gallery/tee-gallery-2.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 218,
    imgSrc: "/images/shop/gallery/tee-gallery-3.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 219,
    imgSrc: "/images/shop/gallery/tee-gallery-4.jpg",
    alt: "image-gallery",
    wowDelay: ".5s",
    quickAdd: false,
    price: 29.66,
  },
  {
    id: 220,
    imgSrc: "/images/shop/gallery/tee-gallery-5.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 221,
    imgSrc: "/images/shop/gallery/tee-gallery-6.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 222,
    imgSrc: "/images/shop/gallery/tee-gallery-7.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 223,
    imgSrc: "/images/shop/gallery/tee-gallery-8.jpg",
    alt: "image-gallery",
    wowDelay: ".6s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
];

export const products36 = [
  {
    id: 224,
    imgSrc: "/images/products/sock1.jpg",
    imgHoverSrc: "/images/products/sock2.jpg",
    title: "Skarpetki Love is Love",
    price: 39,
  },
  {
    id: 225,
    imgSrc: "/images/products/sock3.jpg",
    imgHoverSrc: "/images/products/sock4.jpg",
    title: "Skarpetki Planets",
    price: 39,
  },
  {
    id: 226,
    imgSrc: "/images/products/sock5.jpg",
    imgHoverSrc: "/images/products/sock6.jpg",
    title: "Skarpetki Hotter than Tabasco",
    price: 39,
  },
  {
    id: 227,
    imgSrc: "/images/products/sock7.jpg",
    imgHoverSrc: "/images/products/sock8.jpg",
    title: "Skarpetki About Love",
    price: 70,
  },
  {
    id: 228,
    imgSrc: "/images/products/sock9.jpg",
    imgHoverSrc: "/images/products/sock10.jpg",
    title: "Skarpetki Water Sport",
    price: 9,
  },
  {
    id: 229,
    imgSrc: "/images/products/sock11.jpg",
    imgHoverSrc: "/images/products/sock12.jpg",
    title: "Pacific Performance Socks",
    price: 39,
  },
  {
    id: 230,
    imgSrc: "/images/products/sock13.jpg",
    imgHoverSrc: "/images/products/sock14.jpg",
    title: "Pacific and Co Smile Club Socks",
    price: 39,
  },
];

export const products37 = [
  {
    id: 231,
    imgSrc: "/images/shop/gallery/sock-gallery1.jpg",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 232,
    imgSrc: "/images/shop/gallery/sock-gallery2.jpg",
    wowDelay: ".3s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 233,
    imgSrc: "/images/shop/gallery/sock-gallery3.jpg",
    wowDelay: ".4s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 234,
    imgSrc: "/images/shop/gallery/sock-gallery4.jpg",
    wowDelay: ".5s",
    quickAdd: false,
    tooltip: "View product",
    title: "demo title",
    price: 29.66,
  },
  {
    id: 235,
    imgSrc: "/images/shop/gallery/sock-gallery5.jpg",
    wowDelay: ".6s",
    quickAdd: false,
    tooltip: "View product",
    title: "demo title",
    price: 29.66,
  },
];

export const products38 = [
  {
    id: 236,
    imgSrc: "/images/products/jewerly-1.jpg",
    imgHoverSrc: "/images/products/jewerly-2.jpg",
    title: "Pearl Ring - Lea",
    price: 138.0,
    colors: [
      {
        name: "Golden Yellow",
        colorClass: "bg_golden-yellow",
        imgSrc: "/images/products/jewerly-1.jpg",
      },
      {
        name: "Silver White",
        colorClass: "bg_white",
        imgSrc: "/images/products/jewerly-2.jpg",
      },
    ],
  },
  {
    id: 237,
    imgSrc: "/images/products/jewerly-3.jpg",
    imgHoverSrc: "/images/products/jewerly-4.jpg",
    title: "Trillium Studs",
    price: 138.0,
    colors: [
      {
        name: "Golden Yellow",
        colorClass: "bg_golden-yellow",
        imgSrc: "/images/products/jewerly-3.jpg",
      },
    ],
  },
  {
    id: 238,
    imgSrc: "/images/products/jewerly-5.jpg",
    imgHoverSrc: "/images/products/jewerly-6.jpg",
    title: "Disc Necklace - Mari",
    price: 138.0,
    colors: [
      {
        name: "Golden Yellow",
        colorClass: "bg_golden-yellow",
        imgSrc: "/images/products/jewerly-5.jpg",
      },
      {
        name: "Dark Green",
        colorClass: "bg_dark-green",
        imgSrc: "/images/products/jewerly-6.jpg",
      },
    ],
  },
  {
    id: 239,
    imgSrc: "/images/products/jewerly-7.jpg",
    imgHoverSrc: "/images/products/jewerly-8.jpg",
    title: "Mother of Pearl Ring - Birgit",
    price: 138.0,
    colors: [
      {
        name: "Golden Yellow",
        colorClass: "bg_golden-yellow",
        imgSrc: "/images/products/jewerly-7.jpg",
      },
    ],
  },
  {
    id: 240,
    imgSrc: "/images/products/jewerly-9.jpg",
    imgHoverSrc: "/images/products/jewerly-10.jpg",
    title: "Three Stone Ring - Edith",
    price: 138.0,
    colors: [],
  },
];

export const products39 = [
  {
    id: 241,
    imgSrc: "/images/products/sneaker-3.png",
    imgHoverSrc: "/images/products/sneaker-4.png",
    alt: "image-product",
    title: "POLAR NIGHT PACK PACK 2 FUSION 2.0",
    price: 144.95,
    colors: [
      {
        colorClass: "bg_dark",
        imgSrc: "/images/products/sneaker-3.png",
        tooltip: "Black",
      },
      {
        colorClass: "bg_white",
        imgSrc: "/images/products/sneaker-4.png",
        tooltip: "White",
      },
    ],
  },
  {
    id: 242,
    imgSrc: "/images/products/sneaker-5.png",
    imgHoverSrc: "/images/products/sneaker-6.png",
    alt: "image-product",
    title: "SHADOW 6000 BEIGE",
    price: 144.95,
    colors: [
      {
        colorClass: "bg_dark",
        imgSrc: "/images/products/sneaker-5.png",
        tooltip: "Black",
      },
      {
        colorClass: "bg_white",
        imgSrc: "/images/products/sneaker-6.png",
        tooltip: "White",
      },
    ],
  },
  {
    id: 243,
    imgSrc: "/images/products/sneaker-7.jpg",
    imgHoverSrc: "/images/products/sneaker-8.jpg",
    alt: "image-product",
    title: "RIVALRY 86 LOW WONDER",
    price: 144.95,
    colors: [
      {
        colorClass: "bg_dark",
        imgSrc: "/images/products/sneaker-7.jpg",
        tooltip: "Black",
      },
      {
        colorClass: "bg_white",
        imgSrc: "/images/products/sneaker-8.jpg",
        tooltip: "White",
      },
    ],
  },
  {
    id: 244,
    imgSrc: "/images/products/sneaker-9.png",
    imgHoverSrc: "/images/products/sneaker-10.png",
    alt: "image-product",
    title: "SAMBA OG SHOES",
    price: 144.95,
    colors: [
      {
        colorClass: "bg_dark",
        imgSrc: "/images/products/sneaker-9.png",
        tooltip: "Black",
      },
      {
        colorClass: "bg_white",
        imgSrc: "/images/products/sneaker-10.png",
        tooltip: "White",
      },
    ],
  },
];

export const products40 = [
  {
    id: 245,
    imgSrc: "/images/products/black-4.jpg",
    imgHoverSrc: "/images/products/black-5.jpg",
    alt: "image-product",
    title: "Regular Fit Oxford Shirt",
    oldPrice: "$36.00",
    price: 25.0,
    sizes: ["3 sizes available"],
    sale: "-31%",
    colors: [
      {
        name: "Orange",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-4.jpg",
      },
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/dark-blue-2.jpg",
      },
      {
        name: "Beige",
        colorClass: "bg_beige",
        imgSrc: "/images/products/beige.jpg",
      },
      {
        name: "Light Blue",
        colorClass: "bg_light-blue",
        imgSrc: "/images/products/light-blue.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-7.jpg",
      },
    ],
    filterCategories: ["Best seller", "Graphics", "Jeans"],
  },
  {
    id: 246,
    imgSrc: "/images/products/light-green-1.jpg",
    imgHoverSrc: "/images/products/light-green-2.jpg",
    alt: "image-product",
    title: "Loose Fit Sweatshirt",
    price: 25.0,
    sizes: ["M", "L", "XL"],
    colors: [
      {
        name: "Light Green",
        colorClass: "bg_light-green",
        imgSrc: "/images/products/light-green-1.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-3.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue.jpg",
      },
      {
        name: "Dark Blue",
        colorClass: "bg_dark-blue",
        imgSrc: "/images/products/dark-blue.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-6.jpg",
      },
      {
        name: "Light Grey",
        colorClass: "bg_light-grey",
        imgSrc: "/images/products/light-grey.jpg",
      },
    ],
    filterCategories: ["Best seller", "Sale", "Jeans"],
  },
  {
    id: 247,
    imgSrc: "/images/products/white-8.jpg",
    imgHoverSrc: "/images/products/black-6.jpg",
    alt: "image-product",
    title: "Loose Fit Hoodie",
    price: 10.0,
    sizes: ["4 sizes available"],
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-8.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-7.jpg",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/blue-2.jpg",
      },
    ],
    filterCategories: ["Best seller", "Sale", "Graphics"],
  },
  {
    id: 248,
    imgSrc: "/images/products/black-9.jpg",
    imgHoverSrc: "/images/products/black-10.jpg",
    alt: "image-product",
    title: "Slim Fit Fine-knit Turtleneck Sweater",
    price: 20.0,
    sizes: ["S", "M", "L", "XL"],
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-9.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/white-9.jpg",
      },
    ],
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
  {
    id: 249,
    imgSrc: "/images/products/black-18.jpg",
    imgHoverSrc: "/images/products/black-19.jpg",
    alt: "image-product",
    title: "Regular Fit Windbreaker",
    price: 114.95,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark sold-out",
        imgSrc: "/images/products/black-18.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white sold-out",
        imgSrc: "/images/products/white-13.jpg",
      },
    ],
    soldOut: true,
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
  {
    id: 250,
    imgSrc: "/images/products/black-20.jpg",
    imgHoverSrc: "/images/products/black-21.jpg",
    alt: "image-product",
    title: "Regular Fit Polo Shirt",
    price: 135.0,
    sizes: ["4 sizes available"],
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/black-20.jpg",
      },
      {
        name: "Taupe",
        colorClass: "bg_taupe",
        imgSrc: "/images/products/taupe-1.jpg",
      },
    ],
    preOrder: true,
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
  {
    id: 251,
    imgSrc: "/images/products/white-14.jpg",
    imgHoverSrc: "/images/products/white-15.jpg",
    alt: "image-product",
    title: "Regular Fit Linen-blend Shirt",
    price: 9.95,
    colors: [
      {
        name: "White",
        colorClass: "bg_white sold-out",
        imgSrc: "/images/products/white-14.jpg",
        tooltip: "White",
      },
    ],
    soldOut: true,
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
  {
    id: 252,
    imgSrc: "/images/products/black-23.jpg",
    imgHoverSrc: "/images/products/black-22.jpg",
    alt: "image-product",
    title: "Slim Fit Fine-knit Cotton Sweater",
    price: 31.99,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark sold-out",
        imgSrc: "/images/products/black-23.jpg",
        tooltip: "Black",
      },
      {
        name: "White",
        colorClass: "bg_white sold-out",
        imgSrc: "/images/products/white-16.jpg",
        tooltip: "White",
      },
      {
        name: "Blue",
        colorClass: "bg_blue-2 sold-out",
        imgSrc: "/images/products/blue-4.jpg",
        tooltip: "Blue",
      },
    ],
    soldOut: true,
    filterCategories: ["Best seller", "Sale", "Graphics", "Jeans"],
  },
];

export const products41 = [
  {
    id: 253,
    imgSrc: "/images/products/kid-1.jpg",
    imgHoverSrc: "/images/products/kid-2.jpg",
    width: 742,
    height: 742,
    title: "Dayton Teddy Fleece Jacket Burnt",
    price: 449.0,
    stockAvailable: 23,
    stockSold: 999,
    progress: 3,
    sizeInfo: null,
    saleInfo: null,
  },
  {
    id: 254,
    imgSrc: "/images/products/kid-14.jpg",
    imgHoverSrc: "/images/products/kid-11.jpg",
    width: 360,
    height: 360,
    title: "Dayton Teddy Fleece",
    price: 449.0,
    stockAvailable: 18,
    stockSold: 12,
    progress: 60,
    sizeInfo: "4 sizes available",
    saleInfo: ["-24%", "Pre-Order"],
  },
  {
    id: 255,
    imgSrc: "/images/products/kid-3.jpg",
    imgHoverSrc: "/images/products/kid-4.jpg",
    width: 360,
    height: 360,
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    price: 99.0,
    oldPrice: "$100.00",
    stockAvailable: 12,
    stockSold: 321,
    progress: 5,
    sizeInfo: null,
    saleInfo: ["-1%"],
  },
  {
    id: 256,
    imgSrc: "/images/products/kid-12.jpg",
    imgHoverSrc: "/images/products/kid-13.jpg",
    width: 360,
    height: 360,
    title: "Vanilla White",
    price: 35.0,
    stockAvailable: 50,
    stockSold: 50,
    progress: 50,
    sizeInfo: null,
    saleInfo: null,
  },
  {
    id: 257,
    imgSrc: "/images/products/kid-14.jpg",
    imgHoverSrc: "/images/products/kid-11.jpg",
    width: 360,
    height: 360,
    title: "Dayton Teddy Fleece",
    price: 449.0,
    stockAvailable: 18,
    stockSold: 12,
    progress: 60,
    sizeInfo: "4 sizes available",
    saleInfo: ["-24%", "Pre-Order"],
  },
];

export const products42 = [
  {
    id: 258,
    imgSrc: "/images/products/kid-1.jpg",
    imgHoverSrc: "/images/products/kid-2.jpg",
    title: "Dayton Teddy Fleece Jacket Burnt",
    price: 449.0,
    sale: false,
  },
  {
    id: 259,
    imgSrc: "/images/products/kid-3.jpg",
    imgHoverSrc: "/images/products/kid-4.jpg",
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    price: 99.0,
    oldPrice: "$100.00",
    sale: "-1%",
  },
  {
    id: 260,
    imgSrc: "/images/products/kid-6.jpg",
    imgHoverSrc: "/images/products/kid-6.jpg",
    title: "Wool Fleece Suit Moss Green",
    price: 45.0,
    oldPrice: "$55.00",
    sale: "-18%",
    colors: [
      {
        name: "Brown",
        imgSrc: "/images/products/kid-6.jpg",
        colorClass: "bg_brown",
      },
      {
        name: "Red",
        imgSrc: "/images/products/kid-5.jpg",
        colorClass: "bg_red",
      },
    ],
  },
  {
    id: 261,
    imgSrc: "/images/products/kid-15.jpg",
    imgHoverSrc: "/images/products/kid-10.jpg",
    title: "Ribbed Short Sleeve T-Shirt",
    price: 12.08,
    oldPrice: "$16.00",
    sale: "-25%",
    colors: [
      {
        name: "Light Green",
        imgSrc: "/images/products/kid-15.jpg",
        colorClass: "bg_light-green",
      },
      {
        name: "Yellow",
        imgSrc: "/images/products/kid-7.jpg",
        colorClass: "bg_yellow",
      },
      {
        name: "Pink",
        imgSrc: "/images/products/kid-8.jpg",
        colorClass: "bg_pink",
      },
    ],
  },
  {
    id: 262,
    imgSrc: "/images/products/kid-9.jpg",
    imgHoverSrc: "/images/products/kid-10.jpg",
    title: "Ottawa Lined Recycled Rain Set Flintstone",
    price: 48.0,
    oldPrice: "$53.00",
    sale: "-9%",
    colors: [
      {
        name: "Black",
        imgSrc: "/images/products/kid-9.jpg",
        colorClass: "bg_dark",
      },
      {
        name: "Pink",
        imgSrc: "/images/products/kid-10.jpg",
        colorClass: "bg_pink",
      },
    ],
  },
  {
    id: 263,
    imgSrc: "/images/products/kid-12.jpg",
    imgHoverSrc: "/images/products/kid-13.jpg",
    title: "Vanilla White",
    price: 35.0,
    sale: false,
  },
];

export const products43 = [
  {
    id: 264,
    imgSrc: "/images/shop/gallery/kid_gallery_1.jpg",
    alt: "image-gallery",
    wowDelay: "0s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 265,
    imgSrc: "/images/shop/gallery/kid_gallery_2.jpg",
    alt: "image-gallery",
    wowDelay: ".1s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 266,
    imgSrc: "/images/shop/gallery/kid_gallery_3.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 267,
    imgSrc: "/images/shop/gallery/kid_gallery_4.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 268,
    imgSrc: "/images/shop/gallery/kid_gallery_5.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 269,
    imgSrc: "/images/shop/gallery/kid_gallery_6.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "demo title",
    price: 29.66,
  },
  {
    id: 270,
    imgSrc: "/images/shop/gallery/kid_gallery_3.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "demo title",
    price: 29.66,
  },
];

export const lookbookProducts = [
  {
    id: 271,
    imgSrc: "/images/shop/products/img-p2.png",
    altText: "lookbook-item",
    title: "Jersey thong body",
    price: 112.0,
    width: "98",
    height: "70",
    isLookBookProduct: true,
  },
  {
    id: 272,
    imgSrc: "/images/shop/products/img-p4.png",
    altText: "",
    title: "Ribbed modal T-shirt",
    price: 20.0,
    width: "98",
    height: "70",
    isLookBookProduct: true,
  },
  {
    id: 273,
    imgSrc: "/images/shop/products/img-p5.png",
    altText: "",
    title: "Ribbed Tank Top",
    price: 20.0,
    width: "98",
    height: "70",
    isLookBookProduct: true,
  },
  {
    id: 274,
    imgSrc: "/images/item/pr1.jpg",
    altText: "lookbook-item",
    title: "Stripe Tee Rust and Ivory",
    price: 41.0,
    width: "137",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 275,
    imgSrc: "/images/item/pr7.jpg",
    altText: "",
    title: "Cushioned Dog Harness Moonstone",
    price: 48.0,
    width: "137",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 276,
    imgSrc: "/images/item/pr10.jpg",
    altText: "",
    title: "The Scot Collar Mint",
    price: 56.0,
    width: "137",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 277,
    imgSrc: "/images/shop/products/lb-furniture-1.jpg",
    altText: "lookbook-item",
    title: "Brasilia Lounge Chair",
    price: 982.0,
    width: "944",
    height: "676",
    isLookBookProduct: true,
  },
  {
    id: 278,
    imgSrc: "/images/shop/products/lb-furniture-2.jpg",
    altText: "",
    title: "AJ Wall Sconce",
    price: 982.0,
    width: "944",
    height: "676",
    isLookBookProduct: true,
  },
  {
    id: 279,
    imgSrc: "/images/shop/products/lb-furniture-3.jpg",
    altText: "",
    title: "Eames Lounge Chair",
    price: 6500.0,
    width: "944",
    height: "676",
    isLookBookProduct: true,
  },
  {
    id: 280,
    imgSrc: "/images/shop/products/lb-furniture-4.jpg",
    altText: "",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 1366.0,
    width: "944",
    height: "676",
    isLookBookProduct: true,
  },
  {
    id: 281,
    imgSrc: "/images/products/kid-14.jpg",
    altText: "lookbook-item",
    title: "Dayton Teddy Fleece",
    price: 130.0,
    width: "360",
    height: "360",
    isLookBookProduct: true,
  },
  {
    id: 282,
    imgSrc: "/images/products/kid-3.jpg",
    altText: "",
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    price: 99.0,
    width: "360",
    height: "360",
    isLookBookProduct: true,
  },
  {
    id: 283,
    imgSrc: "/images/products/kid-9.jpg",
    altText: "",
    title: "Ottawa Lined Recycled Rain Set Flintstone",
    price: 48.0,
    width: "360",
    height: "360",
    isLookBookProduct: true,
  },
  {
    id: 284,
    imgSrc: "/images/products/lb-men-1.jpg",
    altText: "",
    title: "Loose Fit Hoodie",
    price: 20.0,
    width: "167",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 285,
    imgSrc: "/images/products/lb-men-2.jpg",
    altText: "",
    title: "Ribbed Tank Top",
    price: 20.0,
    width: "127",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 286,
    imgSrc: "/images/item/setup-gear-1.jpg",
    altText: "",
    title: " Tag Case",
    price: 20.0,
    width: "111",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 287,
    imgSrc: "/images/item/setup-gear-2.jpg",
    altText: "",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 288,
    imgSrc: "/images/item/setup-gear-3.png",
    altText: "",
    title: "AJ Wall Sconce",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 289,
    imgSrc: "/images/item/setup-gear-1.jpg",
    altText: "",
    title: " Tag Case",
    price: 20.0,
    width: "111",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 290,
    imgSrc: "/images/item/setup-gear-2.jpg",
    altText: "",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 291,
    imgSrc: "/images/item/setup-gear-3.png",
    altText: "",
    title: "AJ Wall Sconce",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 292,
    imgSrc: "/images/item/setup-gear-1.jpg",
    altText: "",
    title: " Tag Case",
    price: 20.0,
    width: "111",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 293,
    imgSrc: "/images/item/setup-gear-2.jpg",
    altText: "",
    title: "MG501 Cuba Lounge Chair, Paper Cord",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 294,
    imgSrc: "/images/item/setup-gear-3.png",
    altText: "",
    title: "AJ Wall Sconce",
    price: 20.0,
    width: "168",
    height: "120",
    isLookBookProduct: true,
  },
  {
    id: 311,
    imgSrc: "/images/products/pink-handbag-1.jpg",
    imgAlt: "lookbook-item",
    width: 668,
    height: 668,
    title: "Alice Mini - Dusty Rose",
    price: 130.0,
    isLookBookProduct: true,
  },
  {
    id: 312,
    imgSrc: "/images/products/green-handbag-1.jpg",
    imgAlt: "Amanda - Green",
    width: 668,
    height: 668,
    title: "Amanda - Green",
    price: 138.0,
    isLookBookProduct: true,
  },
  {
    id: 313,
    imgSrc: "/images/products/brown-handbag-1.jpg",
    imgAlt: "Grace - Brown",
    width: 668,
    height: 668,
    title: "Grace - Brown",
    price: 138.0,
    isLookBookProduct: true,
  },
  {
    id: 314,
    imgSrc: "/images/products/sneaker-2.jpg",
    imgAlt: "lookbook-item",
    width: 120,
    height: 180,
    title: "REGULAR FIT WINDBREAKER",
    price: 130.0,
    isLookBookProduct: true,
  },
  {
    id: 315,
    imgSrc: "/images/products/sneaker-1.png",
    imgAlt: "Polar Night Pack 2 Fusion 2.0",
    width: 120,
    height: 120,
    title: "POLAR NIGHT PACK 2 FUSION 2.0",
    price: 99.0,
    isLookBookProduct: true,
  },
];

export const featuredProducts = [
  {
    id: 295,
    imgSrc: "/images/products/img-feature-1.png",
    width: 92,
    height: 128,
    title: "Jersey thong body",
    price: 105.95,
  },
  {
    id: 296,
    imgSrc: "/images/products/img-feature-2.png",
    width: 92,
    height: 138,
    title: "Lace-trimmed Satin Camisole Top",
    price: 24.95,
  },
  {
    id: 297,
    imgSrc: "/images/products/img-feature-3.png",
    width: 92,
    height: 138,
    title: "Linen-blend Tank Top",
    price: 16.95,
  },
];
export const bundleproducts = [
  {
    id: 298,
    imgSrc: "/images/shop/products/hmgoepprod27.jpg",
    title: "Shein Ezwear Shoulder Tee",
    price: 39.0,
  },
  {
    id: 299,
    imgSrc: "/images/shop/products/hmgoepprod28.jpg",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
  {
    id: 300,
    imgSrc: "/images/shop/products/hmgoepprod29.jpg",
    title: "Y'all Tee - Blush",
    price: 38.0,
  },
];

export const galleryData = [
  {
    id: 301,
    imgSrc: "/images/shop/gallery/gallery-7.jpg",
    alt: "image-gallery-7",
    wowDelay: ".2s",
    QuickAdd: true,
    tooltip: "Quick Add",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
  {
    id: 302,
    imgSrc: "/images/shop/gallery/gallery-3.jpg",
    alt: "image-gallery-3",
    wowDelay: ".3s",
    QuickAdd: true,
    tooltip: "Quick Add",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
  {
    id: 303,
    imgSrc: "/images/shop/gallery/gallery-5.jpg",
    alt: "image-gallery-5",
    wowDelay: ".4s",
    QuickAdd: true,
    tooltip: "Quick Add",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
  {
    id: 304,
    imgSrc: "/images/shop/gallery/gallery-8.jpg",
    alt: "image-gallery-8",
    wowDelay: ".5s",

    tooltip: "View product",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
  {
    id: 305,
    imgSrc: "/images/shop/gallery/gallery-6.jpg",
    alt: "image-gallery-6",
    wowDelay: ".6s",

    tooltip: "View product",
    title: "Y'all Tee - Black",
    price: 38.0,
  },
];

export const products44 = [
  {
    id: 306,
    imgSrc: "/images/item/search-grocey1.jpg",
    imgAlt: "Berry World Blueberries",
    imgWidth: 68,
    imgHeight: 68,
    title: "Berry World Blueberries",
    price: 4.0,
    hasOldPrice: false,
  },
  {
    id: 307,
    imgSrc: "/images/item/search-grocey2.jpg",
    imgAlt: "Berry World Strawberries",
    imgWidth: 68,
    imgHeight: 63,
    title: "Berry World Strawberries",
    price: 30.25,
    oldPrice: 40.25,
    hasOldPrice: true,
  },
  {
    id: 308,
    imgSrc: "/images/item/search-grocey3.jpg",
    imgAlt: "Fish Said Fred Sea Bass Fillets",
    imgWidth: 68,
    imgHeight: 68,
    title: "Fish Said Fred Sea Bass Fillets",
    price: 6.0,
    hasOldPrice: false,
  },
  {
    id: 309,
    imgSrc: "/images/item/search-grocey4.jpg",
    imgAlt: "M&S Full-Bodied Greek Kalamata Olives",
    imgWidth: 68,
    imgHeight: 68,
    title: "M&S Full-Bodied Greek Kalamata Olives",
    price: 7.0,
    hasOldPrice: false,
  },
  {
    id: 310,
    imgSrc: "/images/item/search-grocey5.jpg",
    imgAlt: "M&S Roast Lamb Dinner",
    imgWidth: 68,
    imgHeight: 63,
    title: "M&S Roast Lamb Dinner",
    price: 3.7,
    oldPrice: 4.7,
    hasOldPrice: true,
  },
];

export const featuredProducts2 = [
  {
    id: 316,
    imgSrc: "/images/products/img-feature-1.png",
    imgAlt: "image-feature",
    imgWidth: 92,
    imgHeight: 128,
    title: "Jersey thong body",
    price: 105.95,
    currency: "$",
  },
  {
    id: 317,
    imgSrc: "/images/products/img-feature-2.png",
    imgAlt: "image-feature",
    imgWidth: 92,
    imgHeight: 138,
    title: "Lace-trimmed Satin Camisole Top",
    price: 24.95,
    currency: "€",
  },
  {
    id: 318,
    imgSrc: "/images/products/img-feature-3.png",
    imgAlt: "image-feature",
    imgWidth: 92,
    imgHeight: 138,
    title: "Linen-blend Tank Top",
    price: 16.95,
    currency: "$",
  },
];
export const tfLoopItems = [
  {
    id: 319,
    imgSrc: "/images/products/white-3.jpg",
    imgAlt: "",
    imgWidth: 720,
    imgHeight: 1005,
    title: "Cotton jersey top",
    compareAtPrice: 10.0,
    price: 8.0,
    salePrice: 8.0,
    isOnSale: true,
  },
  {
    id: 320,
    imgSrc: "/images/products/white-2.jpg",
    imgAlt: "",
    imgWidth: 668,
    imgHeight: 932,
    title: "Mini crossbody bag",
    price: 18.0,
    isOnSale: false,
  },
  {
    id: 321,
    imgSrc: "/images/products/white-1.jpg",
    imgAlt: "",
    imgWidth: 720,
    imgHeight: 1005,
    title: "Oversized Printed T-shirt",
    price: 18.0,
    isOnSale: false,
  },
];

export const productBestcell = [
  {
    id: 322,
    imgSrc: "/images/shop/products/gift-card-2.jpg",
    imgAlt: "",
    imgWidth: 720,
    imgHeight: 1005,
    title: "Boutique eGift Card",
    price: 18.0,
    isOnSale: false,
  },
];

export const products45 = [
  {
    id: 323,
    imgSrc: "/images/products/bark-phone-blue.jpg",
    imgHoverSrc: "/images/products/bark-phone-blue2.jpg",
    alt: "image-product",
    title: "Case with MagSafe",
    price: 19.99,
    colors: [
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/bark-phone-blue.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/bark-phone-black.jpg",
      },
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/bark-phone-orange.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/bark-phone-green.jpg",
      },
      {
        name: "Light Brown",
        colorClass: "bg_light-brown",
        imgSrc: "/images/products/bark-phone-light-brown.jpg",
      },
    ],
  },
  {
    id: 324,
    imgSrc: "/images/products/cable-black.jpg",
    imgHoverSrc: "/images/products/cable-black2.jpg",
    alt: "image-product",
    title: "MagSafe 3 Cable",
    price: 39,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/cable-black.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/cable-white.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/cable-cream.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/cable-grey.jpg",
      },
    ],
  },
  {
    id: 325,
    imgSrc: "/images/products/headphone-white.jpg",
    imgHoverSrc: "/images/products/headphone-red.jpg",
    alt: "image-product",
    title: "Beats Studio Buds",
    price: 199,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-white.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-red2.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-black.jpg",
      },
    ],
  },
  {
    id: 326,
    imgSrc: "/images/products/albert-black.jpg",
    imgHoverSrc: "/images/products/albert-white.jpg",
    alt: "image-product",
    title: "Blue Ocean Band",
    price: 9,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/albert-black.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/albert-white.jpg",
      },
    ],
  },
  {
    id: 327,
    imgSrc: "/images/products/cable-black.jpg",
    imgHoverSrc: "/images/products/cable-black2.jpg",
    alt: "image-product",
    title: "MagSafe 3 Cable",
    price: 39,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/cable-black.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/cable-white.jpg",
      },
      {
        name: "Cream",
        colorClass: "bg_cream",
        imgSrc: "/images/products/cable-cream.jpg",
      },
      {
        name: "Grey",
        colorClass: "bg_grey",
        imgSrc: "/images/products/cable-grey.jpg",
      },
    ],
  },
  {
    id: 328,
    imgSrc: "/images/products/headphone-white.jpg",
    imgHoverSrc: "/images/products/headphone-red.jpg",
    alt: "image-product",
    title: "Beats Studio Buds",
    price: 199,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-white.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-red2.jpg",
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-black.jpg",
      },
    ],
  },
];
export const products46 = [
  {
    id: 329,
    imgSrc: "/images/products/headphone-red2.jpg",
    imgHoverSrc: "/images/products/headphone-red.jpg",
    title: "Beats Studio Buds",
    price: 199,
    preOrder: true,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/headphone-white.jpg",
      },
      {
        name: "Red",
        colorClass: "bg_red",
        imgSrc: "/images/products/headphone-red2.jpg",
        active: true,
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/headphone-black.jpg",
      },
    ],
  },
  {
    id: 330,
    imgSrc: "/images/products/bark-phone-blue.jpg",
    imgHoverSrc: "/images/products/bark-phone-blue2.jpg",
    title: "Case with MagSafe",
    price: 19.99,
    colors: [
      {
        name: "Blue",
        colorClass: "bg_blue-2",
        imgSrc: "/images/products/bark-phone-blue.jpg",
        active: true,
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/bark-phone-black.jpg",
      },
      {
        name: "Orange",
        colorClass: "bg_orange-3",
        imgSrc: "/images/products/bark-phone-orange.jpg",
      },
      {
        name: "Green",
        colorClass: "bg_green",
        imgSrc: "/images/products/bark-phone-green.jpg",
      },
      {
        name: "Light Brown",
        colorClass: "bg_light-brown",
        imgSrc: "/images/products/bark-phone-light-brown.jpg",
      },
    ],
  },
  {
    id: 331,
    imgSrc: "/images/products/wireless-charging-black2.jpg",
    imgHoverSrc: "/images/products/wireless-charging-white3.jpg",
    title: "Smart Watch Series 6",
    price: 299.99,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/wireless-charging-white2.jpg",
        active: true,
      },
      {
        name: "Dark",
        colorClass: "bg_dark",
        imgSrc: "/images/products/wireless-charging-black2.jpg",
      },
    ],
  },
  {
    id: 332,
    imgSrc: "/images/products/wireless-charging-black.jpg",
    imgHoverSrc: "/images/products/wireless-charging-white.jpg",
    title: "Ultra Thin Laptop",
    price: 999.99,
    colors: [
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/wireless-charging-white.jpg",
        active: true,
      },
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/wireless-charging-black.jpg",
      },
    ],
  },
  {
    id: 333,
    imgSrc: "/images/products/albert-black.jpg",
    imgHoverSrc: "/images/products/albert-white.jpg",
    title: "Wireless Earbuds",
    price: 149.99,
    colors: [
      {
        name: "Black",
        colorClass: "bg_dark",
        imgSrc: "/images/products/albert-black.jpg",
        active: true,
      },
      {
        name: "Orange",
        colorClass: "bg_orange",
        imgSrc: "/images/products/albert-orange.jpg",
      },
      {
        name: "White",
        colorClass: "bg_white",
        imgSrc: "/images/products/albert-white2.jpg",
      },
    ],
  },
];
export const galleryItems3 = [
  {
    id: 334,
    imgSrc: "/images/shop/gallery/accessories-gallery1.jpg",
    alt: "image-gallery",
    wowDelay: "0s",
    title: "Beats Studio Buds",
    price: 199,
    quickAdd: true,
  },
  {
    id: 335,
    imgSrc: "/images/shop/gallery/accessories-gallery2.jpg",
    alt: "image-gallery",
    wowDelay: ".1s",
    quickAdd: true,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 336,
    imgSrc: "/images/shop/gallery/accessories-gallery3.jpg",
    alt: "image-gallery",
    wowDelay: ".2s",
    quickAdd: true,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 337,
    imgSrc: "/images/shop/gallery/accessories-gallery4.jpg",
    alt: "image-gallery",
    wowDelay: ".3s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 338,
    imgSrc: "/images/shop/gallery/accessories-gallery5.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 339,
    imgSrc: "/images/shop/gallery/accessories-gallery6.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 340,
    imgSrc: "/images/shop/gallery/accessories-gallery7.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 341,
    imgSrc: "/images/shop/gallery/accessories-gallery8.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
  {
    id: 342,
    imgSrc: "/images/shop/gallery/accessories-gallery5.jpg",
    alt: "image-gallery",
    wowDelay: ".4s",
    quickAdd: false,
    title: "Beats Studio Buds",
    price: 199,
  },
];
export const products47 = [
  {
    id: 343,
    imgSrc: "/images/products/grocery-1.jpg",
    imgHoverSrc: "/images/products/grocery-2.jpg",
    title: "Berry World Strawberries",
    oldPrice: 40.25,
    price: 30.25,
    available: 23,
    sold: 80,
    salePercentage: -31,
    timer: 8007500,
  },
  {
    id: 344,
    imgSrc: "/images/products/grocery-3.jpg",
    imgHoverSrc: "/images/products/grocery-4.jpg",
    title: "M&S Roast Lamb Dinner",
    oldPrice: 4.7,
    price: 3.7,
    available: 5,
    sold: 105,
    salePercentage: -31,
    timer: 8007500,
  },
  {
    id: 345,
    imgSrc: "/images/products/grocery-5.jpg",
    imgHoverSrc: "/images/products/grocery-6.jpg",
    title: "Brown Rice Drink",
    price: 1.76,
    available: 82,
    sold: 40,
    timer: 8007500,
  },
  {
    id: 346,
    imgSrc: "/images/products/grocery-3.jpg",
    imgHoverSrc: "/images/products/grocery-4.jpg",
    title: "M&S Roast Lamb Dinner",
    oldPrice: 4.7,
    price: 3.7,
    available: 5,
    sold: 105,
    salePercentage: -31,
    timer: 8007500,
  },
];

export const products48 = [
  {
    id: 347,
    imgSrc: "/images/products/vegetable1.jpg",
    imgHoverSrc: "/images/products/vegetable2.jpg",
    title: "Ocado Little Gem Lettuce",
    price: 85,
    filterCategories: ["Meat", "Tomatoes", "Soup"],
  },
  {
    id: 348,
    imgSrc: "/images/products/fruits.jpg",
    imgHoverSrc: "/images/products/fruits2.jpg",
    title: "Ocado Red Seedless Grapes",
    price: 4,
    filterCategories: ["Meat", "Oils", "Soup"],
  },
  {
    id: 349,
    imgSrc: "/images/products/meat1.jpg",
    imgHoverSrc: "/images/products/meat2.jpg",
    title: "Silere Merino Lamb Boneless Leg Joint",
    price: 13,
    filterCategories: ["Meat", "Oils"],
  },
  {
    id: 350,
    imgSrc: "/images/products/fruits3.jpg",
    imgHoverSrc: "/images/products/fruits4.jpg",
    title: "Ocado Little Gem Lettuce",
    price: 85,
    filterCategories: ["Meat", "Tomatoes", "Soup"],
  },
  {
    id: 351,
    imgSrc: "/images/products/fruits5.jpg",
    imgHoverSrc: "/images/products/fruits6.jpg",
    title: "M&S Full-Bodied Greek Kalamata Olives",
    price: 7,
    filterCategories: ["Meat", "Oils", "Tomatoes"],
  },
  {
    id: 352,
    imgSrc: "/images/products/milk.jpg",
    imgHoverSrc: "/images/products/milk2.jpg",
    title: "The Collective Suckies Peach & Apricot Yoghurt",
    price: 75,
    filterCategories: ["Meat", "Oils", "Soup"],
  },
  {
    id: 353,
    imgSrc: "/images/products/fish.jpg",
    imgHoverSrc: "/images/products/fish2.jpg",
    title: "Fresh Atlantic Salmon",
    price: 20,
    filterCategories: ["Meat", "Tomatoes", "Soup"],
  },
  {
    id: 354,
    imgSrc: "/images/products/fruits3.jpg",
    imgHoverSrc: "/images/products/fruits4.jpg",
    title: "Ocado Little Gem Lettuce",
    price: 85,
    filterCategories: ["Meat", "Oils", "Tomatoes"],
  },
];
export const products49 = [
  {
    id: 355,
    imgSrc: "/images/products/kid-3.jpg",
    imgHoverSrc: "/images/products/kid-4.jpg",
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    oldPrice: 100,
    price: 99,
    available: 60,
    sold: 40,
    salePercentage: -1,
  },
  {
    id: 356,
    imgSrc: "/images/products/kid-1.jpg",
    imgHoverSrc: "/images/products/kid-2.jpg",
    title: "Dayton Teddy Fleece Jacket Burnt",
    oldPrice: 449,
    price: 449,
    available: 154,
    sold: 46,
    salePercentage: 0,
  },
  {
    id: 357,
    imgSrc: "/images/products/stroller-baby-white.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black3.jpg",
    title: "Butterfly Complete Stroller",
    oldPrice: 600,
    price: 499,
    available: 180,
    sold: 60,
    salePercentage: -17,
  },
  {
    id: 358,
    imgSrc: "/images/products/nes-pink.jpg",
    imgHoverSrc: "/images/products/nes-white.jpg",
    title: "Cozy Nes",
    oldPrice: 65,
    price: 60,
    available: 180,
    sold: 60,
    salePercentage: -8,
  },
  {
    id: 359,
    imgSrc: "/images/products/kid-1.jpg",
    imgHoverSrc: "/images/products/kid-2.jpg",
    title: "Dayton Teddy Fleece Jacket Burnt",
    oldPrice: 449,
    price: 449,
    available: 154,
    sold: 46,
    salePercentage: 0,
  },
];
export const products50 = [
  {
    id: 360,
    imgSrc: "/images/products/kid-5.jpg",
    imgHoverSrc: "/images/products/kid-6.jpg",
    title: "Wool Fleece Suit Moss Green",
    oldPrice: 55,
    price: 45,
  },
  {
    id: 361,
    imgSrc: "/images/products/stroller-baby-kaki2.jpg",
    imgHoverSrc: "/images/products/stroller-baby-black3.jpg",
    title: "Butterfly Complete Stroller",
    oldPrice: 515,
    price: 499,
  },
  {
    id: 362,
    imgSrc: "/images/products/nes-pink.jpg",
    imgHoverSrc: "/images/products/nes-white.jpg",
    title: "Cozy Nes",
    oldPrice: 65,
    price: 60,
  },
  {
    id: 363,
    imgSrc: "/images/products/kid-9.jpg",
    imgHoverSrc: "/images/products/kid-10.jpg",
    title: "Ottawa Lined Recycled Rain Set Flintstone",
    oldPrice: 53,
    price: 48,
  },
  {
    id: 364,
    imgSrc: "/images/products/kid-3.jpg",
    imgHoverSrc: "/images/products/kid-4.jpg",
    title: "S2 Anatomical Pacifiers Ivory/Sage",
    oldPrice: 100,
    price: 99,
  },
  {
    id: 365,
    imgSrc: "/images/products/kid-7.jpg",
    imgHoverSrc: "/images/products/kid-8.jpg",
    title: "Kids Jacket",
    oldPrice: 75,
    price: 70,
  },
];

export const allProducts = [
  ...products1,
  ...products2,
  ...products3,
  ...galleryItems,
  ...products4,
  ...products5,
  ...galleryItems2,
  ...products6,
  ...products7,
  ...productSlides,
  ...products9,
  ...products10,
  ...products11,
  ...products12,
  ...products13,
  ...products14,
  ...products15,
  ...products16,
  ...products17,
  ...products18,
  ...products19,
  ...products20,
  ...products21,
  ...products22,
  ...products23,
  ...products24,
  ...products25,
  ...products26,
  ...products27,
  ...products28,
  ...products29,
  ...products30,
  ...products31,
  ...products32,
  ...products33,
  ...products34,
  ...products35,
  ...products36,
  ...products37,
  ...products38,
  ...products39,
  ...products40,
  ...products41,
  ...products42,
  ...products43,
  ...lookbookProducts,
  ...featuredProducts,
  ...bundleproducts,
  ...galleryData,
  ...products44,
  ...featuredProducts2,
  ...tfLoopItems,
  ...productBestcell,
  ...products45,
  ...products46,
  ...galleryItems3,
  ...products47,
  ...products48,
  ...products49,
  ...products50,
];
