import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../context/ProductContext'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import './ViewPay.css'
import carrito from '../assets/visa.png';
import Axios from 'axios';
import logo4 from '../assets/logo-2.png';
import logo2 from '../assets/palaces3.png';
import { useNavigate } from 'react-router-dom';
import Banner from './Banners/Banner';

export const ViewPay = () =>  {
    const { setTextBanner } = useContext( ProductContext );
    const { setShowLogo } = useContext ( ProductContext );
    const { selectedProduct, setSelectedProduct } = useContext( ProductContext );
    const [ subtotal, setSubTotal ] = useState(0);
    const [ total, setTotal ] = useState(0);
    const [ totalEnvio, setTotalEnvio ] = useState(0);
    const [ token, setToken ] = useState('');
    const [ url, setUrl ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ address, setAddress ] = useState(''); 
    const [ number, setNumber ] = useState(''); 
    const [ comuna, setComuna ] = useState(''); 
    const [ disabledPay, setDisabledPay ] = useState(false); 
    const navigate = useNavigate();
    const [regiones, setRegiones] = useState([]);
    const [comunas, setComunas] = useState([]);
    const [selectedRegionId, setSelectedRegionId] = useState('');
    const [selectedComunaId, setSelectedComunaId] = useState('');

    const deleteProduct = (id) =>{
        setSelectedProduct((products) => products.filter((product) => product.id !== id));
    }

    const addProduct=()=>{
        try{
            Axios.post("https://ms-bff-palaces-0e9f257a364f.herokuapp.com/store/product/insert/pay",{
                transaction: token,
                userTransaction: 'Test',
                total: total,
                product: selectedProduct,
                status: 'PEN',
                email: email,
                address: address + " " + number + " Comuna" + comuna
            }).then((response)=>{
                console.log('listo!');
            });
        }catch(err){

        }
    }

    const getToken = async (totalPay) => {
        try
        {
            Axios.post("https://ms-bff-palaces-0e9f257a364f.herokuapp.com/get-token",{
                amount: total
            }).then((response)=>{
                console.log(response,'reponse');
                setToken(response.data.token);
                setUrl(response.data.url);
            })
        }catch(err){
            console.log('getToken',err);
        }
    }

    useEffect(() => {
        try{
            let valueSend = 0;
            setTextBanner('Despacho gratis por marcha blanca');

            if(selectedProduct.length == 0){
            navigate("/");
            }
            setShowLogo(false);
            const calculateTotal = async () => {
                let totalSum = 0; // Local variable for sum calculation
                selectedProduct.forEach(item => {
                    totalSum += item.value_total; // Add each product's value_total
                });

                setSubTotal(totalSum); // Update subtotal without shipping

                if (totalSum > 0) {
                    if (totalSum > 50000) {
                        setTotalEnvio(0); // Free shipping for orders above 50000
                        setTotal(totalSum); // Total is just the subtotal
                    } else {
                        setTotalEnvio(valueSend); // Fixed shipping cost for orders below 50000
                        setTotal(totalSum + valueSend); // Total with shipping cost
                    }
                    await total;
                    if(total>0){
                        getToken(total); // Call getToken with the updated total
                        setDisabledPay(true);
                    }
                    
                }
            };

            calculateTotal();    
        }catch(err){
            navigate("/");
        }
    }, [selectedProduct,total]); 

    return (
        <>
            <div className='container-banner'>
                <Banner></Banner>
            </div>
            <div className="backgroundCarrito">
                
                <form action="https://webpay3g.transbank.cl/webpayserver/initTransaction" method="POST">
                    <Row>
                        <Col sm={8} xs={12}>
                            <Row>
                                <Col md={1} xs={1}>
                                    <img className="img-react img-logo-pay mobile" src={logo4} alt="BigCo Inc. logo"/>
                                    <img className="img-react img-logo-pay desktop" src={logo4} alt="BigCo Inc. logo"/>
                                </Col>
                                <Col md={6} xs={6}><h5 className="textCarrito">TU CARRITO</h5></Col>
                            </Row>
                            <Row className="box-item">
                                {selectedProduct.map((product)=>(
                                    <>
                                <Col md={3} xs={3} className="box-item-details">
                                    <img className='imgproduct' src={product.images[0].image} alt="BigCo Inc. logo"/>
                                    </Col> 
                                    <Col lg="7" xs={6} className="box-item-details">
                                        <div className="margin-auto">
                                            <p className='marginbottom5 fontsize1'>{product.name}</p>
                                            <p className='marginbottom5 fontsize2'>{product.description}</p>
                                            <p className='marginbottom5 fontsize2'>TAMAÑO {product.size}</p>
                                            <p className='marginbottom5 fontsize2'>TOTAL {product.value_total.toLocaleString('es-CL', {
                                            style: 'currency',
                                            currency: 'CLP',
                                            })
                                            }</p>
                                        </div>
                                        </Col>
                                        <Col lg="2" xs="2" className="box-item-details">                            
                                        <button className='buttonNone margintopimg' onClick={() => {
                                            deleteProduct(product.id)
                                        }}>
                                            <svg className='svg-tash' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
                                        </button>

                                    </Col>
                                    </>
                                    ))}
                            </Row>
                            <Row className="box-item margintop10">
                                <Col md={6}>
                                    <h5 className="textDiscount">CORREO</h5>   
                                    <input className="inputdescuento" onChange={(event)=>{
                                    setEmail(event.target.value);
                                }} required></input> 
                                </Col>
                                <Col md={6}>
                                    <h5 className="textDiscount">DIRRECCIÓN</h5>   
                                    <input className="inputdescuento" type='text' onChange={(event)=>{
                                    setAddress(event.target.value);
                                }}  required></input> 
                                </Col>
                                <Col>
                                    <h5 className="textDiscount">NÚMERO</h5>   
                                    <input className="inputdescuento" type='number' onChange={(event)=>{
                                    setNumber(event.target.value);
                                }}  required></input> 
                                </Col>
                                <Col>
                                    <h5 className="textDiscount">COMUNA</h5>   
                                    <input className="inputdescuento" type='text' onChange={(event)=>{
                                    setComuna(event.target.value);
                                }}  required></input> 
                                </Col>
                                {/*<select value={selectedRegionId} onChange={handleRegionChange}>
                    {regiones.map((region) => (
                    <option key={region.id} value={region.id}>
                        {region.nombre}
                    </option>
                    ))}
                </select>

                <select value={selectedComunaId} onChange={handleComunaChange}>
                    {comunas.map((comuna) => (
                    <option key={comuna.id} value={comuna.id}>
                        {comuna.nombre}
                    </option>
                    ))}
                    </select>*/}
                            </Row>
                        </Col>

                        <Col sm={4} xs={12}>
                        <h5 className="textResumen">RESUMEN DEL PEDIDO</h5>
                            <Row className="box-item-details">
                                <Col> 
                                SUBTOTAL       
                                </Col>
                                <Col>   
                                <p className="textalignRight">{subtotal.toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                                })
                                }</p>
                                </Col>
                            </Row>
                            <Row className="box-item-details">
                                <Col> 
                                    ENVÍO       
                                </Col>
                                <Col>   
                                    <p className="textalignRight">{totalEnvio.toLocaleString('es-CL', {
                                    style: 'currency',
                                    currency: 'CLP',
                                    })
                                    }</p>
                                </Col>
                            </Row>
                            <Row className="box-item-details">
                                <Col> 
                                <h5 className="textDiscount">INGRESA TU CÓDIGO DE DESCUENTO</h5>   
                                <input className="inputdescuento"></input> 
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row className="box-item-details">
                                <Col> 
                                    <p className="compraActiva font-total">TOTAL</p>     
                                </Col>
                                <Col>   
                                    <p className="textalignRight compraActiva font-total">{total.toLocaleString('es-CL', {
                                    style: 'currency',
                                    currency: 'CLP',
                                    })
                                    }</p>
                                </Col>
                            </Row>
                            <Row className="box-item-details">
                                <Col className="textalignRight">   
                                        <input type="hidden" name="token_ws" value={token}/>
                                        
                                        <Button type='submit' className="btnPago" onClick={()=>{
                                        addProduct();
                                        }}>PROCEDER AL PAGO</Button>
                                    
                                    <button className="buttonNone continuaCompra" onClick={() =>{
                                            navigate("/")
                                        }}><i> Continúa Comprando</i></button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </form>
            </div>
        </>
    )
        
}