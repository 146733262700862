
import React, { useContext, useState,useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './Cart.css';
import { Link, NavLink } from 'react-router-dom';
import { ProductContext } from '../context/ProductContext';
import { ViewProduct } from './ViewProduct';
import { useNavigate } from "react-router-dom";

export const StoreProduct = (product) => { 
  const navigate = useNavigate();
  const { productView, setProductView } = useContext( ProductContext );
  const { name, description, image, value_total, discount, flag, brand, size, images} = product.product;

  const handleDataUpdate = (item) => {
    setProductView(item);
    navigate("/product-details");
  };

 const styles = {
    colCart: {
      marginTop: 30,
    },
  };
  
  return (
       <div className="container container-product" onClick={() => handleDataUpdate(product)}>
            <Col className="colCart margintop0" style={styles.colCart}>
                <div className="label-general">
                    <img className='img-product' src={images[0].image}></img>
                    {value_total > 39000
                    ? <div className='container-free'>
                        <p className='label-free'>ENVÍO GRATIS</p>
                    </div>
                    :null}

                    <div>
                        <Container className='contianer-absolute'>
                            <Row className='container-price'>
                                <Col md={ discount ? 2 : 8 } xs={ discount ? 12 : 9 } className='margin0'><p className="valorProduct">{(value_total-( value_total* discount/100)).toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                                })
                                }</p></Col>
                            </Row>
                            <Row className={discount ? 'container-price-discount' : 'container-price-no no-discount'}>
                                {discount ? (<Col md={3} xs={6} className='margin0 container-white'><p className="valorProductNo">{ value_total.toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                                })
                                }</p></Col>) : (null)}
                                <Col md={3} xs={4} className='margin0 container-discount container-white'><p className='disocunt'>{discount}%</p></Col>
                            </Row>
                        </Container>
                    </div>
                    <h1 className="h2Cart"><strong>{ name }</strong> <br></br> Brand {brand}  Size {size}</h1>
                </div>
            </Col>
        </div>
  )
}
