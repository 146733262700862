import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ProductContext } from '../context/ProductContext';
import { Link, NavLink } from 'react-router-dom'; // Import for navigation links
import { useEffect } from 'react';
import "./Navbar.css";
import logoVisa from '../assets/visa.png';
import { useNavigate } from 'react-router-dom';

export const CardShopping = () => {
    const { selectedProduct, setSelectedProduct } = useContext( ProductContext );
    const { showNavBar, setNavBar } = useContext ( ProductContext );
    const { showLogo, setShowLogo } = useContext ( ProductContext );
    const { show, setShow } = useContext ( ProductContext );    
    const deleteProduct = (id) =>{
        setSelectedProduct((products) => products.filter((product) => product.id !== id));
    }
    const [ total , setTotal ] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        try{
            const handleTotal = async () =>{
            await selectedProduct;
            let totalSum = 0;
            selectedProduct.forEach((item) => {
                    totalSum += item.value_total;
                });
                setTotal(totalSum);
            }

            handleTotal();
        }catch(err){
            navigate("/");
        }
    }, [selectedProduct]);
    

  return (
    <>
       { showNavBar ?(
        <>
            <div className='backgroundShopping'></div>
            <div className="cardHome">
                <Container className="cardsubHome">
                <Row style={{height:'30px'}}>
                        <Col className="tituloCard" sm={10} xs={10}>
                            <h8>SU CARRO DE COMPRAS  ({selectedProduct?.length}) </h8>
                        </Col>
                        <Col sm={2} xs={1}>
                            <button className="buttonNone close" onClick={() => {
                            setNavBar(!showNavBar);
                            }}>X</button>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='container-space'>
                        <div className='scroll-card'>
                    {selectedProduct.length > 0 ? (
                        <>
                        {selectedProduct.map( product=>(
                            <>
                                <Row className='margintop'>
                                    <Col md={3} xs={4}><img className='img-shopping' src={product.images[0].image}></img></Col>
                                    <Col md={6} xs={5} style={{marginRight:'20px'}}>
                                        <p className='title-neto'>{product.name}</p>
                                        <p className='label-available margintop10less'>
                                            {product.available === "Disponible" ? (
                                            <> {/* Fragment for better readability */}
                                                <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                                                </svg>
                                                Disponible
                                            </>
                                            ) : (
                                            <> {/* Fragment for better readability */}
                                            <svg className='svg-icon-x' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
                                                No Disponible
                                            </>
                                            )}
                                            {' '} {/* Add a space after the conditional rendering */}
                                            talla {product.size}
                                        </p>
                                        <p className='title-neto margintop10less'>{product.value_neto.toLocaleString('es-CL', {
                                            style: 'currency',
                                            currency: 'CLP',
                                            })
                                            }</p>
                                    </Col>
                                    <Col xs={1}>
                                            <button className='buttonNone' onClick={() => {
                                                deleteProduct(product.id)
                                            }}>
                                                <svg className='svg-tash' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
                                            </button>
                                    </Col>
                                </Row>
                            </>
                        ))}
                        <Row style={{marginTop:'20px'}}>
                            <Col className="textAlignleft" md={4} xs={2}>
                                <p className='label-price'>SUBTOTAL</p>              
                            </Col>
                            <Col className="textAlignright">
                                <p className='label-price'>{total.toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                                })
                                }</p>             
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row style={{marginTop:'20px'}}>
                            <Col className="textAlignleft" md={6} xs={6}>
                                <p className='label-price-total'>TOTAL DEL PEDIDO</p> 
                            </Col>
                            
                            <Col className="textAlignright" md={6} xs={6}>
                                <p className='label-price-total'>{total.toLocaleString('es-CL', {
                                    style: 'currency',
                                    currency: 'CLP',
                                    })
                                    }</p>             
                            </Col>
                            <Col className='textAlignright' md={12}>
                                <p className='margin-top-less'>Hasta 6 cuotas de $ {Math.round(total/6).toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                                })} sin interés</p>             
                            </Col>
                        </Row>
                    </>
                    ): (
                        <>
                            <div style={{textAlign:'center'}}>
                            <svg className='svg-icon-sad' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M175.9 448c-35-.1-65.5-22.6-76-54.6C67.6 356.8 48 308.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208s-93.1 208-208 208c-28.4 0-55.5-5.7-80.1-16zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM128 369c0 26 21.5 47 48 47s48-21 48-47c0-20-28.4-60.4-41.6-77.7c-3.2-4.4-9.6-4.4-12.8 0C156.6 308.6 128 349 128 369zm128-65c-13.3 0-24 10.7-24 24s10.7 24 24 24c30.7 0 58.7 11.5 80 30.6c9.9 8.8 25 8 33.9-1.9s8-25-1.9-33.9C338.3 320.2 299 304 256 304zm47.6-96a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zm-128 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>
                            <p className='title-empty'>Tu carrito está vacío</p>
                            </div>
                        </>
                    )
                    }
                        </div>
                        <div>
                            {selectedProduct.length > 0 ? (
                            <Row style={{marginBottom:'80px'}}>
                                <Col className="textAligncenter " md={12}>
                                    <button type="button" className="button-pay" onClick={() =>{
                                        setShowLogo(false)
                                        navigate("/product-pay")
                                        setNavBar(false)
                                    }}>REALIZAR PEDIDO</button>
                                </Col>
                            </Row>)
                            :<></>}
                        </div>
                    </div>
                </Container>
            </div> 
        </>
       ):(<p style={{marginTop:'300px'}}>probando</p>)}
    </>
  )
}
