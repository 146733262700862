import { createContext } from "react";

export const ProductContext = createContext({
    productAll: [],
    productView: [],
    selectedProduct: [],
    showNavBar: false,
    showLogo: true,
    filterExist: [],
    showMenuMob: false,
    textBanner: ''
});