

import React, { useContext, useState,useEffect } from 'react'
import { ProductContext } from '../context/ProductContext';
import Axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StoreProduct } from './StoreProduct';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Product.css'
import Header2 from './headers/Header2';

export const Product = () => {
  const [productlist,setProductList]=useState([]);
  const {  productAll, setProductAll  } = useContext( ProductContext );
  const {  setFilterExist  } = useContext( ProductContext );
  const { showLogo, setShowLogo } = useContext ( ProductContext );

  useEffect(()=>{
    try{
      setShowLogo(true);
    Axios.get("https://ms-bff-palaces-0e9f257a364f.herokuapp.com/store/product")
      .then((response)=>{
        setProductAll(response.data);
        localStorage.setItem('selectedProduct', JSON.stringify(response.data));
        const seen = {}; // Objeto para almacenar claves vistas
        const filteredData = response.data
        .map(item => ({ size: item.size, brand: item.brand, discount: item.discount }))
        .filter((item, index, self) => {
          const key = `${item.size}-${item.brand}-${item.discount}`;
          // Revisa si la clave ya existe en el hash
          return !seen.hasOwnProperty(key) && (seen[key] = true);
        });

        setFilterExist(filteredData);
      })
    }catch(err){
      
    }
  },[]);

  return (
    <>
      <Header2 />
      <div
      className='container-product'
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          margin: '12v0px'
        }}
      >
      
          <Row className='marginbottom60mob'>
            <Col sm={2}>{/*<Filter></Filter>*/}</Col>
            <Col sm={12}>
             {/* <Row>
                <Col className='margin0 mobile' style={{padding:'0px'}}>
                  <div className='container-message'>
                    <p>Producto originales, y con despacho gratis, 3 cuotas sin interés.</p>
                  </div>
                </Col>
              </Row><div className='label-contruction'>
                    <p>Esperando tus productos para que tengas una bonita experiencia</p>
                   </div>*/}
                                 
              <Row className='margin30'>
                {productAll.map( product=>(
                  <Col md={3} xs={6} className='container-product-item'>
                    <StoreProduct product={product}/>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
      </div>
    </>
  )
}
