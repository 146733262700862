import React, { useContext, useEffect, useState } from 'react'
import { ProductContext } from '../context/ProductContext'
import Axios from 'axios';
import './ViewConfirm.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom';
import logo4 from '../assets/logo-2.png';

export const ViewConfirm = () => {

    const { setShowLogo } = useContext ( ProductContext );
    const [authorized, setAuthorized ] = useState(false);
    const [amount, setAmount ] = useState(0);
    const [ auth , setAuth] = useState(0);
    const [ date , setDate] = useState('');

    const navigate = useNavigate();

    const updateTransactionStatus = async (transactionId) => {
        try {
            const response = await Axios.post('https://ms-bff-palaces-0e9f257a364f.herokuapp.com/store/product/update/pay', { transaction: transactionId });
            console.log('Transaction status updated:', response.data);
        } catch (error) {
            console.error('Error updating transaction status:', error);
            // Handle error appropriately (e.g., display an error message to the user)
        }
    };

    useEffect(() => {
        try{
            setShowLogo(false);
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            Axios.get("https://ms-bff-palaces-0e9f257a364f.herokuapp.com/get-status/"+token).then((response)=>{
                if(response.data.statusResponse.status=="AUTHORIZED"){
                    console.log(response.data.statusResponse);
                    setDate(response.data.statusResponse.transaction_date)
                    setAmount(response.data.statusResponse.amount);
                    setAuth(response.data.statusResponse.authorization_code);
                    setAuthorized(true);
                    updateTransactionStatus(token);
                }
            })
        }catch(err){
            navigate("/");
        }
    }, []); 
    return (
        <>
        {authorized 
            ?   
                <div className='container-confirm'>
                    <Row className='container-ok'>
                        <Col className='container-details' xs={12}>
                            <div className="payment-success-container">
                                <img className="img-logo-confirm" src={logo4} alt="BigCo Inc. logo"/>
                                <hr></hr>
                                <div className='container-icon'>
                                    <svg className='svg-check' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                                </div>
                                <h1>¡Tu pago ha sido procesado exitosamente!</h1>

                                <div className="payment-details">
                                    <ul>
                                     <li><p className='label-price'> Monto: {amount.toLocaleString('es-CL', {
                                        style: 'currency',
                                        currency: 'CLP',
                                        })} CLP</p></li>
                                    <li><p className='label-details'>Fecha: {date.substring(0,10)}</p></li>
                                    <li><p className='label-details'>ID de transacción: {auth}</p></li>
                                    <li><p className='label-details'>Estado: Pago exitoso </p>{/* Replace with actual payment method */}</li>
                                    </ul>
                                </div>

                                <button className="back-to-shop" onClick={() =>{
                                    navigate("/")
                                }}>Volver a la tienda</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            : <p>Cargando....</p>
        }
        </>
    )
}
