import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom'; // Import for navigation links
import "./Navbar.css";
import logo4 from '../assets/logo-2.png';
import logo2 from '../assets/palaces3.png';
import logo3 from '../assets/menu.png';
import logo5 from '../assets/centro.png';
import { ProductContext } from '../context/ProductContext';
import { CardShopping } from './CardShopping';
import Banner from './Banners/Banner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const NavBar = () => {
    const { showLogo} = useContext ( ProductContext );
    const { selectedProduct } = useContext( ProductContext );
    const { showNavBar, setNavBar } = useContext ( ProductContext );
    const [isOpen] = useState(false); // State for mobile menu toggle
    const [show, setShow] = useState(true);
    const { showMenuMob , setshowMenuMob} = useContext ( ProductContext );

    const [productsLength, setProductLength] = useState(0); 
    const { setTextBanner } = useContext( ProductContext );

    useEffect(()=>{
        setTextBanner('');
    },[]);

    return (
        <>
        {showLogo == false
            ? <p></p>
            :  <>
                <nav className={`navbar ${isOpen ? 'active' : ''}`}>
        <Banner></Banner>

       { showMenuMob ?(
        <div className="mobile">
            <div className="cardHome mobile ">
                <Container className="cardsubHome">
                    <Row style={{height:'30px'}}>
                        <Col className="tituloCard" sm={9} xs={10}>
                        </Col>
                        <Col sm={3} xs={1}>
                            <button className="buttonNone close" onClick={() => {
                                setshowMenuMob(!showMenuMob);
                            }}>X</button>
                        </Col>
                    </Row>
                    <Row>
                        <ul>
                            <li className="nav-item nav-item-label">
                                <NavLink to="/" activeClassName="active">
                                    COLECCIÓN
                                </NavLink>
                            </li>
                            <li className="nav-item nav-item-label">
                                <NavLink to="/" activeClassName="active">
                                  OUTLET
                                </NavLink>
                            </li>
                        </ul>
                    </Row>
                </Container>
            </div> 
        </div>
       ):(null)}


        <div className="container-nav container-navleft">
                <ul className={`nav-menu ${isOpen ? 'active' : ''}`}>
                    <li className='nav-item mobile'>
                        <button className='buttonNone'>
                            {/*<button className='buttonNone' onClick={() => {
                                setshowMenuMob(!showMenuMob);
                            }}>
                                <img className="img-react img-menu mobile" src={logo3} alt="BigCo Inc. logo"/>
                            </button>*/}
                            <img className="img-react img-menu desktop" src={logo4} alt="BigCo Inc. logo"/>
                        </button>
                    </li>
                    <li className="nav-item marginmenu" style={{marginTop: '4px'}}>
                         <NavLink to="/" style={{fontSize: '23px'}}>
                        PALACES
                        </NavLink>
                    </li>
                    <li className="nav-item nav-item-label">
                        <NavLink to="/">
                        COLECCIÓN
                        </NavLink>
                    </li>
                    <li className="nav-item nav-item-label">
                        <NavLink to="/">
                        OUTLET
                        </NavLink>
                    </li>
                    <li className="nav-item icon-shopping">
                        {selectedProduct.length > 0 
                        ?  <div class="contenedor-circulo"><span class="numero">{selectedProduct.length}</span>
                            </div>
                        : <></>}
                        
                        <button className='buttonNone' onClick={() => {
                            setShow(true);
                            setNavBar(!showNavBar);
                            console.log(show,'show');
                            console.log(selectedProduct,'selectedproduct');
                        }}>
                            <img className="img-react img-carro" src={logo5} alt="BigCo Inc. logo"/>
                        </button>
                    </li>
                    <li className="nav-item icon-user">
                        <svg className='svg-icon-user' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                    </li>
                    {/* Add more menu items here */}
                </ul>
            </div>
            <div className='mobile'>
            </div>
                </nav>
                {showNavBar ? (
                    <CardShopping></CardShopping>
                ):(<></>)}
            </>
        }
        </>
    )
}
